/**
 * Popup Layout Component
 * description: has textarea or message and buttons, on button click return the textarea value
 * @param placeholder: the placeholder of the textarea.
 * @param onClick = return event with the textarea value when you click the Save Button.
 * @param disabled = if the submit button should be disabled if there is no text inside the textarea
 * @param onChange = on textarea entry send the value to the parent
 * @param closePopUp: when we close the layout update the parent state so that it hides the component.
 * @param popupTitle: Title of the popup, if there is none we ain't showing it.
 * @param buttons: array with objects for the buttons:
 *  buttonColor: "tertiary",
 *  buttonType: "fill",
 *  buttonName: "Reject",
 *  action: "rejected",
 *  disabled: true
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import CloseIcon from "@assets/icons/Close";
import Button from "@atoms/Button/button.component";
import InputLabel from "@atoms/Fields/InputLabel/input-label.components";

import styles from "./popup-layout.module.scss";

type PopupLayoutProps = {
  [key: string]: any;
};

function PopupLayout({
  placeholder,
  onConfirmClick,
  closePopUp,
  onChange,
  disabled,
  children,
  popupTitle,
  buttons,
  buttonView,
  confirmButtonText,
  cancelButtonText,
}: PopupLayoutProps) {
  const { t } = useTranslation();
  const [keyPressed, setKeyPressed] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    setDisableSubmit(disabled);
  }, [disabled]);

  const onChangeHandler = (e: any) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const renderContent = () => {
    if (placeholder && onChange) {
      if (children) {
        return (
          <>
            <div>{children}</div>
            <div className={styles.textAreaWrap}>
              <InputLabel label={t("Reason")} required={true} />
              <textarea
                className={styles.textarea}
                rows={6}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => onChangeHandler(e)}
                placeholder={placeholder}
              />
            </div>
          </>
        );
      }
      return (
        <div className={styles.textAreaWrap}>
          <InputLabel label={t("Reason")} required={true} />
          <textarea
            className={styles.textarea}
            rows={4}
            onChange={(e) => onChangeHandler(e)}
            onKeyDown={(e) => e.preventDefault()}
            placeholder={placeholder}
          />
        </div>
      );
    } else if (children) {
      return <div>{children}</div>;
    }
  };

  // useEffect(() => {
  //   if (keyPressed) {
  //     switch (keyPressed) {
  //       case "Enter": {
  //         if (!disableSubmit && buttons.length === 1) {
  //           onConfirmClick(buttons[0].action);
  //           setDisableSubmit(true);
  //         }
  //         break;
  //       }
  //       case "Escape": {
  //         closePopUp(false);
  //         break;
  //       }
  //     }
  //   }
  //   setKeyPressed(null);
  // }, [keyPressed]);

  const renderButtonText = (button: any) => {
    if (button.action === "confirm") {
      if (confirmButtonText) {
        return t(confirmButtonText);
      } else {
        return t(button.buttonName);
      }
    } else if (button.action === "ignore") {
      if (cancelButtonText) {
        return t(cancelButtonText);
      } else {
        return t(button.buttonName);
      }
    }

    return t(button.buttonName);
  };

  return (
    <div className={styles.layoutWrap} onClick={() => closePopUp(false)}>
      <div className={styles.contentWrap} onClick={(e) => e.stopPropagation()}>
        {popupTitle && (
          <h3>
            {popupTitle.includes("<") ? parse(popupTitle) : t(popupTitle)}
          </h3>
        )}
        <div className={styles.closeButton} onClick={() => closePopUp(false)}>
          <CloseIcon height={20} width={20} />
        </div>
        {renderContent()}
        {buttons && (
          <div
            className={`${styles.buttonsWrap} ${
              buttonView && styles[buttonView]
            }`}>
            {buttons.map((button: any, i: number) => (
              <div className={styles.btnWrap} key={i}>
                <Button
                  size={"large"}
                  onClick={(e) => {
                    e.stopPropagation();
                    onConfirmClick(button.action);
                  }}
                  disabled={disableSubmit}>
                  {renderButtonText(button)}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PopupLayout;
