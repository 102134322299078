/**
 * Context Unite template
 * description: all the context will be here in order to avoid over populating on the APP file
 */

import React from "react";

import WindowContextProvider from "@context/window-resize.context";
import DialogContextProvider from "@context/dialog.context";
import LoaderContextProvider from "@context/loader.context";
import UserContextProvider from "@context/UserContext/user.context";

type ContextUniteProps = {
  children: React.ReactNode,
}

const ContextUnite = ({children}: ContextUniteProps) => {
  return (
    <UserContextProvider>
      <WindowContextProvider>
        <DialogContextProvider>
          <LoaderContextProvider>
            {children}
          </LoaderContextProvider>
        </DialogContextProvider>
      </WindowContextProvider>
    </UserContextProvider>
  )
}

export default ContextUnite;
