import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CookiesProvider, Cookies } from "react-cookie";

import "@lib/config/i18n";
import ScrollToTop from "@lib/helpers/ScrollToTop/scroll-top.func";
import GlobalLoader from "@atoms/GlobalLoader/global-loader.component";
import Header from "@templates/Header/header.component";
import Footer from "@templates/Footer/footer.component";
import PublicFooter from "@templates/Footer/PublicFooter/public-footter.template";
import PageGrid from "@templates/PageGrid/page-grid.template";
import PrivateRoute from "@templates/PrivateRoute/private-route.component";
import ContextUnite from "@templates/ContextUnite/context-unite.template";
import IdentityCallback from "@pages/IdentityCallback/identity-callback.page";
// Temporarily disable InventoryListingPublic
// import InventoryListingPublic from "@pages/InventoryListingPublic/inventory-listing-public.page";
import InventoryDashboard from "@pages/InventoryDashboard/inventory-dashboard.page";
// Temporarily disable VendorTraceability
// import VendorTraceability from "@pages/VendorTrecability/vendor-traceability.page";
import InventoryManagement from "@pages/InventoryManagement/inventory-management.page";
import InventoryArchive from "@pages/InventoryArchive/inventory-archive.page";
import AddInventory from "@pages/InventoryForm/inventory-form.page";
import AccessDenied from "@pages/AccessDenied/access-denied.page";
import NotFound from "@pages/NotFound/not-found.page";
import { Dashboard } from "@pages/InventoryDashboard/dashboard.page";
import IdentitySignout from "@pages/IdentitySignout/identity-signout.page";

import "@base/styles/general.scss";
import "@base/styles/animations.scss";
import {
  addInventory,
  inventoryDashboard,
  // vendorTraceability,
  inventoryEdit,
  inventoryArchive,
  inventoryManage,
} from "@base/globalVariables/page-links";

const cookies = new Cookies();

function App() {
  return (
    <CookiesProvider>
      <div className='App'>
        <ContextUnite>
          <Router>
            <Header />
            <ScrollToTop />
            <PageGrid>
              <Routes>
                <Route
                  path='/'
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={inventoryDashboard}
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path={`${vendorTraceability}/:id`}
                  element={<VendorTraceability />}
                /> */}
                <Route
                  path={`${inventoryManage}/:id`}
                  element={
                    <PrivateRoute>
                      <InventoryManagement />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={addInventory}
                  element={
                    <PrivateRoute>
                      <AddInventory isEdit={false} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={`${inventoryEdit}/:id`}
                  element={
                    <PrivateRoute>
                      <AddInventory isEdit={true} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={`${inventoryArchive}/:id`}
                  element={
                    <PrivateRoute>
                      <InventoryArchive/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/identityCallback'
                  element={<IdentityCallback />}
                />
                <Route path='/error/403' element={<AccessDenied />} />
                <Route path='/error/404' element={<NotFound />} />
                <Route path='/identity-signout' element={<IdentitySignout />} />
              </Routes>
            </PageGrid>
            {cookies.get("id_token") ? <Footer /> : <PublicFooter />}
          </Router>
          <GlobalLoader />
        </ContextUnite>
      </div>
    </CookiesProvider>
  );
}

export default App;
