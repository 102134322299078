const AttachmentIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#333333" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1530_21259)">
                <path d="M8.00061 0C5.60869 0 3.66211 1.94599 3.66211 4.33855V12.5524C3.66211 12.8456 3.89936 13.0828 4.19263 13.0828C4.48571 13.0828 4.72296 12.8456 4.72296 12.5524V4.33855C4.72296 2.5313 6.193 1.06088 8.00061 1.06088C9.80842 1.06088 11.2784 2.5313 11.2784 4.33855V12.8068C11.2784 13.9824 10.3217 14.9392 9.14586 14.9392C9.13712 14.9392 9.12926 14.9439 9.12055 14.9443C9.11123 14.9438 9.10395 14.9392 9.09466 14.9392C7.9189 14.9392 6.96219 13.9824 6.96219 12.8068V7.72707C6.96219 7.15416 7.42779 6.68853 8.00061 6.68853C8.57356 6.68853 9.03919 7.15416 9.03919 7.72707V12.5524C9.03919 12.8456 9.27687 13.0828 9.56955 13.0828C9.86218 13.0828 10.1 12.8456 10.1 12.5524V7.72707C10.1 6.56937 9.15832 5.62768 8.00061 5.62768C6.84307 5.62768 5.90135 6.5694 5.90135 7.72707V12.8068C5.90135 14.5672 7.33355 16 9.09463 16C9.10392 16 9.1112 15.9953 9.12052 15.9948C9.12926 15.9953 9.13712 16 9.14583 16C10.9065 16 12.3391 14.5673 12.3391 12.8068V4.33855C12.3391 1.94599 10.3926 0 8.00061 0Z"/>
            </g>
            <defs>
                <clipPath id="clip0_1530_21259">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default AttachmentIcon;