/**
 * Sign-in/Create account component
 *
 */

import { createPortal } from "react-dom";
import {useTranslation} from "react-i18next";

import { AUTH_ROOT, SIGN_UP_URL } from "@lib/config/config";
import Button from "@atoms/Button/button.component";
import DialogComponent from "@organisms/Dialog/dialog.component";

import "./signin-dialog.styles.scss";

type Props = {
  opened: boolean;
  closeDialog: () => void;
};

const SigninDialog = ({ opened, closeDialog }: Props) => {
  const { t } = useTranslation();

  const dialog = {
    title: "Sign-in to Your Account",
    content: (
      <div style={{ fontSize: "1.5rem", textAlign: "center" }}>
        This page is only available to registered uses
      </div>
    ),
    actions: (
      <div className={"signin-dialog-action-wrap"}>
        <Button
          type={"empty"}
          onClick={() => window.location.replace(AUTH_ROOT)}>
          {t("Log In")}
        </Button>
        <div className={"signin-dialog__text"}>or</div>
        <Button
          type={"empty"}
          onClick={() => (window.location.href = SIGN_UP_URL || "")}>
          {t("Create Account")}
        </Button>
      </div>
    ),
  };

  return (
    <div>
      {opened &&
        createPortal(
          <DialogComponent
            {...dialog}
            closeDialog={closeDialog}
            open={opened}
          />,
          document.getElementById("overlay-root") as HTMLDivElement
        )}
    </div>
  );
};

export default SigninDialog;
