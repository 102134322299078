const DocumentIcon = ({ className }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1595_18743)">
                <path d="M13.9564 3.88725L10.2065 0.137344C10.119 0.0497812 9.9995 0 9.875 0H3.3125C2.53709 0 1.90625 0.630844 1.90625 1.40625V14.5938C1.90625 15.3692 2.53709 16 3.3125 16H12.6875C13.4629 16 14.0938 15.3692 14.0938 14.5938V4.21875C14.0938 4.09094 14.0401 3.97091 13.9564 3.88725ZM10.3438 1.60041L12.4933 3.75H10.8125C10.554 3.75 10.3438 3.53972 10.3438 3.28125V1.60041ZM12.6875 15.0625H3.3125C3.05403 15.0625 2.84375 14.8522 2.84375 14.5938V1.40625C2.84375 1.14778 3.05403 0.9375 3.3125 0.9375H9.40625V3.28125C9.40625 4.05666 10.0371 4.6875 10.8125 4.6875H13.1562V14.5938C13.1562 14.8522 12.946 15.0625 12.6875 15.0625Z" fill="#333333" />
                <path d="M10.8125 6.625H5.1875C4.92862 6.625 4.71875 6.83487 4.71875 7.09375C4.71875 7.35263 4.92862 7.5625 5.1875 7.5625H10.8125C11.0714 7.5625 11.2812 7.35263 11.2812 7.09375C11.2812 6.83487 11.0714 6.625 10.8125 6.625Z" fill="#333333" />
                <path d="M10.8125 8.5H5.1875C4.92862 8.5 4.71875 8.70987 4.71875 8.96875C4.71875 9.22763 4.92862 9.4375 5.1875 9.4375H10.8125C11.0714 9.4375 11.2812 9.22763 11.2812 8.96875C11.2812 8.70987 11.0714 8.5 10.8125 8.5Z" fill="#333333" />
                <path d="M10.8125 10.375H5.1875C4.92862 10.375 4.71875 10.5849 4.71875 10.8438C4.71875 11.1026 4.92862 11.3125 5.1875 11.3125H10.8125C11.0714 11.3125 11.2812 11.1026 11.2812 10.8438C11.2812 10.5849 11.0714 10.375 10.8125 10.375Z" fill="#333333" />
                <path d="M8.9375 12.25H5.1875C4.92862 12.25 4.71875 12.4599 4.71875 12.7188C4.71875 12.9776 4.92862 13.1875 5.1875 13.1875H8.9375C9.19638 13.1875 9.40625 12.9776 9.40625 12.7188C9.40625 12.4599 9.19638 12.25 8.9375 12.25Z" fill="#333333" />
            </g>
            <defs>
                <clipPath id="clip0_1595_18743">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default DocumentIcon;