import {useFormContext} from "react-hook-form";
import React, {forwardRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';

import InputLabel from "@atoms/Fields/Input/InputLabel/input-label.components";
import {DateInputProps} from "@atoms/Fields/Date/date-input.types";

import './date-input.module.scss';
import "./date-input.style.scss";

const Date = forwardRef(({
   key,
   label,
   view = "date",
   readOnly,
   required = false,
   ...props
 }: DateInputProps, ref) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const formOptions: any = props;

  const [open, setOpen] = useState(false);

  const datePlaceholder = () => {
    switch (key) {
      case 'ExpirationDate':
        return `${t('Choose expiration date')}...`;
      case 'DeliveryDate':
        return `${t('Choose delivery date')}...`;
      default:
        return `${t('Choose date')}...`;
    }
  };

  // on change handle the end result
  const handleChange = (newValue: any) => {
    if (newValue) {
      if(view === 'year') {
        const year = newValue.getFullYear();
        setValue(formOptions.name, year, { shouldDirty: true })
      }
      else {
        setValue(formOptions.name, newValue, { shouldDirty: true })
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <InputLabel label={label} required={required} />
      <DatePicker
        {...formOptions}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        views={
          view === 'month' ? ['year', 'month'] : view === 'year' ? ['year'] : ['year', 'month', 'day']
        }
        inputFormat={view === 'month' ? 'MMMM yyyy' : view === 'year' ? 'yyyy' :'dd.MM.yyyy'}
        mask={view === 'month' ? '____.____' : view === 'year' ? '____' :'__.__.____'}
        onChange={(newValue) => handleChange(newValue)}
        renderInput={({ inputRef, inputProps, InputProps }) =>
          !readOnly ? (
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <input
                {...props}
                ref={inputRef}
                placeholder={datePlaceholder()}
                className={'date-field'}
              />
              {InputProps?.endAdornment}
            </Box>
          ) : (
            <input
              {...props}
              ref={inputRef}
              placeholder={formOptions.value || ''}
              disabled={true}
            />
          )
        }
      />
    </LocalizationProvider>
  );
});

Date.displayName = "Date";

export default Date;
