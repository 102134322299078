const UserIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1598_18881)">
                <path d="M13 4.66667C13 2.08986 10.9101 0 8.33333 0C5.75652 0 3.66666 2.08986 3.66666 4.66667C3.66666 7.24348 5.75652 9.33333 8.33333 9.33333C10.9101 9.33333 13 7.24348 13 4.66667ZM4.88406 4.66667C4.88406 2.75942 6.42608 1.21739 8.33333 1.21739C10.2406 1.21739 11.7826 2.75942 11.7826 4.66667C11.7826 6.57391 10.2406 8.11594 8.33333 8.11594C6.42608 8.11594 4.88406 6.57391 4.88406 4.66667Z" />
                <path d="M1.28881 15.9227C1.38029 15.9807 1.47177 16 1.56326 16C1.74622 16 1.94749 15.9034 2.03897 15.7102C3.31974 13.314 5.7349 11.8261 8.33303 11.8261C10.9312 11.8261 13.3463 13.314 14.6454 15.7102C14.7918 15.9807 15.1394 16.0773 15.3955 15.9227C15.6517 15.7681 15.7432 15.401 15.5968 15.1305C14.1148 12.3865 11.3337 10.6667 8.33303 10.6667C5.33237 10.6667 2.55128 12.3865 1.06925 15.1305C0.922874 15.401 1.01436 15.7681 1.28881 15.9227V15.9227Z" />
            </g>
            <defs>
                <clipPath id="clip0_1598_18881">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default UserIcon;