import React from 'react';

import './loading-spinner.styles.scss';

function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}

export default LoadingSpinner;
