/**
 * Main Component for the notification Messages.
 */

import React, { useEffect, useState, memo, useContext } from "react";

import { hideShowPaginationButton } from "@lib/reusable-variables";
import messagesService from "@lib/services/MessagesService/messages-service";
import { envelopeIcon } from "@base/icons/icons.loader";
import Heading from "@atoms/Headings/headings.component";
import { ConversationMessagesTypes } from "@molecules/ConversationMessages/conversation-messages.types";
import NotificationMessage from "@molecules/Message/NotificationMessage/notification-message.component";

import styles from "./notification-messages.module.scss";
import SwitchComponent from "@molecules/Switch/switch.component";
import LoadingSpinner from "@molecules/LoadingSpinner/loading-spinner.component";
import { Message } from "@lib/services/MessagesService/messages-service.types";
import { UserContext } from "@context/UserContext/user.context";

function NotificationMessages() {
  const [messages, setMessages] = useState<Message[]>(); // array of all the messages
  const [hideSystemMessages, setHideSystemMessages] = useState(false); // remember the value in order to call it again on 'Load more'
  const { user } = useContext(UserContext);
  const [isMessageListVisible, setIsMessageListVisible] = useState(false);
  const [messagePageItems, setMessagePageItems] = useState(5); // messages to call
  const [messagesTotalCount, setMessagesTotalCount] = useState(0); // total messages coming from the API
  const [isLoading, setIsLoading] = useState(false);

  const buttonText = "Load More";

  // on Hide System Message toggle -> get the messages again
  useEffect(() => {
    if (isMessageListVisible) {
      getMessages(false);
    } else {
      setMessages([]);
    }
  }, [hideSystemMessages, isMessageListVisible]);

  const getMessages = (nextPage: boolean) => {
    setIsLoading(true);
    const params = {
      pageSize: nextPage ? messagePageItems + 5 : messagePageItems,
      page: 1,
      hideSystem: hideSystemMessages ? 1 : 0,
    };
    messagesService
      .getMessages(params)
      .then((res) => {
        setMessages(res.data.messages);
        setMessagePageItems(res.data._meta.perPage);
        setMessagesTotalCount(res.data._meta.totalCount);
      })
      .catch((e) => {
        //TODO error handling
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.messageWrap}>
      <div className={styles.buttonWrap}>
        <img
          src={envelopeIcon}
          alt='messages'
          onClick={() => setIsMessageListVisible(!isMessageListVisible)}
          className={styles.messageIcon}
        />
        {isLoading && (
          <div className={styles.spinnerWrap}>
            <LoadingSpinner />
          </div>
        )}
      </div>

      {isMessageListVisible && !!messages?.length && (
        <section className={styles.container}>
          <div className={styles.head}>
            <Heading type={"h4"}>Messages</Heading>
            <SwitchComponent
              message={"Hide system messages"}
              trigger={(value: boolean) => {
                setHideSystemMessages(value);
              }}
            />
          </div>
          <div className={styles.messagesList}>
            {messages?.length > 0 &&
              messages?.map(
                (item: ConversationMessagesTypes, index: number) => {
                  return (
                    <NotificationMessage
                      message={item}
                      isAdmin={!!user?.isAdmin}
                      key={index}
                      refresh={() => {
                        getMessages(false);
                      }}
                      archived={false}
                    />
                  );
                }
              )}
          </div>
          {messagesTotalCount >= hideShowPaginationButton && (
            <button
              className={styles.showMore}
              onClick={() => getMessages(true)}>
              {buttonText}
            </button>
          )}
        </section>
      )}
    </div>
  );
}

export default memo(NotificationMessages);
