/**
 * Inventory Archive Page
 * @link: /inventory-archive/{id}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import PageLoader from "@templates/PageLoader/page-loader.template";

const InventoryArchive = () => {
  const { id: previewId } = useParams();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!previewId) {
      navigate("/");
      return;
    }

    localStorage.setItem('inventoryArchiveConfirm', 'true');
    navigate(`/inventory-manage/${previewId}`, { state: { archiveInventory: true } });
  }, [previewId, history]);

  return (
    <PageLoader isLoading={loader}>
      <></>
    </PageLoader>
  );
};

export default InventoryArchive;