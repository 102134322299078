/**
 * IconTextBtn Component
 * description: Components that has Icon Text Button on the same line with close button
 * @props onClick: after you click on the right button send to the parent.
 * @props resolution: under what resolution to stop seeing the icon or button at the right.
 * @props children: the header text of the component
 * @props headerType: what kind of header text to be
 * @props image: what image to be on the right
 * @props imageTitle: the title of the image on the right
 * @props btnType: type of the button default value is 'icon'. Needed to differentiate between icon or btn
 * @props btnCategory: Button needed field for the type
 * @props btnSize: size of the Button
 * @props btnTextSize: text size of the Button
 * @props onClose: send the item that needs to be removed to the parent.
 * Usage: <IconTextBtn onClick={() => console.log('btn clicked')} headerType={'h4'} btnType={'btn'} btnCategory={'pending-approval'} btnSize={'small'}>{item}</IconTextBtn>
 */

import AttachmentIcon from "@assets/icons/Attachment";
import DeleteIcon from "@assets/icons/Trashcan";
import DeleteButton from "@atoms/Button/DeleteButton/delete-button.component";
import HeaderButton from "@molecules/HeaderWithBtn/header-with-btn.component";
import { IconTextBtnProps } from "./icon-text-btn.types";

import styles from "./icon-text-btn.module.scss";

const IconTextBtn = ({
  onClick,
  resolution,
  children,
  headerType = "h2",
  image = DeleteIcon,
  imageTitle = "close btn",
  btnType = "icon",
  btnCategory,
  btnSize = "medium",
  btnTextSize = "small",
  btnText,
  onClose,
  hideRemoveButton = false,
  userRole,
  status,
}: IconTextBtnProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrap}>
        <AttachmentIcon />
      </div>
      <div className={styles.textWrap}>
        <HeaderButton
          onClick={onClick}
          headerType={headerType}
          image={image}
          imageTitle={imageTitle}
          btnType={btnType}
          btnCategory={btnCategory}
          btnSize={btnSize}
          btnTextSize={btnTextSize}
          btnText={btnText}
          userRole={userRole}
          status={status}>
          {children}
        </HeaderButton>

        {!hideRemoveButton && (
          <div className={styles.closeBtn}>
            <DeleteButton onClick={() => onClose && onClose(children)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IconTextBtn;
