/**
 * Full Screen Layout component
 * description: the semi transparent dark background on the full screen. Used for pop up sections
 * @props children
 * @props toggle: send to the parent
 */

import Portal from "@molecules/Portal/portal.component";

import styles from "./full-screen-layout.module.scss";
import { FullScreenLayoutProps } from "./full-screen-layout.types";

const FullScreenLayout = ({children, toggle}: FullScreenLayoutProps) => {
  return (
    <Portal overlay={"overlay"}>
      <div className={styles.fullScreenLayout} onClick={toggle}>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </Portal>
  )
}

export default FullScreenLayout;
