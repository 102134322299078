import { Tooltip, Typography } from "@mui/material";
import AttachmentIcon from "@assets/icons/Attachment";

import styles from "./message-file.module.scss";

type Props = {
  files: any[];
  message: any;
};

const MessageFiles = ({ files, message }: Props) => {
  const showFile = (file: File) => {
    // if (file.FilePath.split('.').pop() === 'pdf')
    //     window.open(`/download/${file.FilePath}`)
    // else {
    //     documentsService.getDocument(file.FilePath).then(r => {
    //         const blobFile = new Blob([r.data]);
    //         const fileURL = URL.createObjectURL(blobFile);
    //         const link = document.createElement('a');
    //         link.href = fileURL;
    //         link.setAttribute(
    //             'download',
    //             file.FileName,
    //         );
    //         link.click();
    //     })
    // }
  };

  return (
    <div className={styles.container}>
      {files?.map((file: any, index: number) => {
        return (
          <div
            key={index}
            className={styles.file}
            onClick={() => {
              showFile(file);
            }}>
            <div className={styles.fileName}>
              <AttachmentIcon />
              {file.fileName?.length > 25 ? (
                <Tooltip
                  enterTouchDelay={0}
                  title={
                    <Typography style={{ fontSize: 13 }}>
                      {file.fileName}
                    </Typography>
                  }>
                  <p className={styles.message}>{file.fileName}</p>
                </Tooltip>
              ) : (
                <p className={styles.message}>{file.fileName}</p>
              )}
            </div>
            <div className={styles.fileInformation}>
              <p>Uploaded {file.date}</p>
              <p>By {message.userName}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageFiles;
