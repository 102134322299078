/**
 * Clear button component
 * description: used inside select input
 * @props: onClick
 * Usage: <ClearButton onClick={clearSelection} />
 */

import {useTranslation} from "react-i18next";

import Button from "@atoms/Button/button.component";

import {ClearButtonProps} from "./clear-button.types";
import styles from './clear-button.module.scss';

const ClearButton = ({onClick}: ClearButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.clearButton}>
      <Button type="link" btnTextSize="small" onClick={onClick} size={"small"}>
        {t("Clear")}
      </Button>
    </div>
  )
}

export default ClearButton;
