import Button from "@atoms/Button/button.component";

import { CancelSubmitBtnProps } from "./cancel-submit-btn.types";
import styles from "./cancel-submit-btn.module.scss";

const CancelSubmitBtn = ({
  cancelType,
  submitType,
  size,
  cancelOnClick,
  submitOnClick,
  cancelText,
  submitText,
  disabledSubmitButton,
}: CancelSubmitBtnProps) => {
  return (
    <div className={styles.submitBtnContainer}>
      <div className={styles.submitBtnWrap}>
        <Button
          type={submitType}
          size={size}
          onClick={submitOnClick}
          disabled={disabledSubmitButton}>
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default CancelSubmitBtn;
