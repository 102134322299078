const DotsIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#333333" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00001 9.20001C8.88367 9.20001 9.60002 8.48366 9.60002 7.6C9.60002 6.71635 8.88367 6 8.00001 6C7.11636 6 6.40001 6.71635 6.40001 7.6C6.40001 8.48366 7.11636 9.20001 8.00001 9.20001Z"/>
            <path d="M1.6 9.20001C2.48365 9.20001 3.2 8.48366 3.2 7.6C3.2 6.71635 2.48365 6 1.6 6C0.716344 6 0 6.71635 0 7.6C0 8.48366 0.716344 9.20001 1.6 9.20001Z"/>
            <path d="M14.4 9.20001C15.2837 9.20001 16 8.48366 16 7.6C16 6.71635 15.2837 6 14.4 6C13.5163 6 12.8 6.71635 12.8 7.6C12.8 8.48366 13.5163 9.20001 14.4 9.20001Z"/>
        </svg>

    )
}

export default DotsIcon