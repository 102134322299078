import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { WindowContext } from "@context/window-resize.context";
import { tabletMediaQuery } from "@base/globalVariables/global.variables";
import MenuDropdownComponent from "@molecules/MenuDropdown/menu-dropdown.component";

import { ActionsListProps } from "./actions-list.types";
import styles from "./actions-list.module.scss";
import { IconButton } from "@mui/material";
import TooltipComponent from "@atoms/Tooltip/tooltip.component";

const ActionsList = ({
  actions,
  orientation = "horizontal",
}: ActionsListProps) => {
  const { clientWidth } = useContext(WindowContext);
  const navigation = useNavigate();
  return (
    <div className={`${styles.actionMenu} ${styles[orientation]}`}>
      <span className={styles.columnItem}>
        {Object.values(actions).map((action) => (
          <div className={styles.actionItem} key={action.name}>
            {/* <Link to={action.link}>{action.name}</Link> */}
            <TooltipComponent tooltipTitle={action.name}>
              <IconButton
                onClick={() => {
                  if (typeof action?.onClick === "function") {
                    action.onClick();
                  } else {
                    action?.link && navigation(action.link);
                  }
                }}>
                {action.icon}
              </IconButton>
            </TooltipComponent>
          </div>
        ))}
      </span>
    </div>
  );
};

export default ActionsList;
