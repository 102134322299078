import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  memo,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  DashboardProvider,
  useDashboardContext,
} from "../../../hooks/useDashboard.context";
import TableView from "../../03.organisms/ListView/TableView/table-view.component";
import { useWindowSize } from "@hooks/useWindowSize";
import inventoryService from "@lib/services/inventory-service";
import PaginationComponent from "@organisms/Pagination/pagination.component";
import ManageColumns from "@organisms/ListView/ManageColumns/manage-columns.component";

import styles from "./dashboard.module.scss";
import { UserContext } from "@context/UserContext/user.context";

const Component = ({ archived }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { width } = useWindowSize();
  const {
    columns,
    filterColumns,
    setFilterPage,
    filterPage,
    filterColumnsArray,
  } = useDashboardContext();
  const { user, setUser } = useContext(UserContext);

  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(false);

  const pageInfo = useMemo(() => {
    if (!filterPage.totalCount) {
      return "";
    }
    const range =
      filterPage.perPage * filterPage.currentPage < filterPage.totalCount
        ? filterPage.perPage * filterPage.currentPage
        : filterPage.totalCount;

    // handle the start number on the different views: list and card
    let start = (filterPage.currentPage - 1) * 10 + 1;

    return `${start} - ${range} out of ${filterPage.totalCount} items`;
  }, [filterPage.currentPage, filterPage.perPage, filterPage.totalCount]);

  const moveNextPage = (page: number) => {
    fetchInventories({ page });
  };

  const fetchInventories = useCallback(
    ({ page }: { page: number }) => {
      setLoading(true);
      const pattern = state ? (state as any).filter || null : null;

      const params = {
        page,
        pageSize: filterPage.perPage,
        pattern: pattern ?? undefined,
        sortBy: filterColumnsArray,
        fields: columns,
      };
      // const oldParams = {
      //   page,
      //   pageSize: 10,
      //   fields: ["inventory", "quantity", "location", "status"],
      //   sortBy: orderFilter,
      //   originCountryId: filtered["originCountryId"],
      //   statusId: filtered["statusId"],
      //   pattern: filter ?? undefined,
      // }

      inventoryService
        .getInventoryList(params)
        .then(({ data }) => {
          setInventories(data.inventoryLots);
          setFilterPage({
            ...data._meta,
            currentPage: data._meta.currentPage,
          });
          setUser(data.user);
          localStorage.setItem("user", JSON.stringify(data.user));
          if (data.user.isPendingUser) {
            //window.location.href = "/error/403";
          }
          setLoading(false);
        })
        .catch((e) => {})
        .finally(() => setLoading(false));
    },
    [state, filterPage.perPage, filterColumnsArray, columns, setFilterPage]
  );
  useEffect(() => {
    if (user?.isPendingUser) {
      navigate("/error/403");
      //window.location.href = "/error/403";
    }
  }, [user]);

  useEffect(() => {
    fetchInventories({ page: 1 });
  }, [state, filterColumns]);

  return (
    <div className={styles.inventoryScreen}>
      <div className={styles.manageContainer}>
        <div>{pageInfo}</div>
        <ManageColumns />
      </div>
      <div className={`${styles.container} ${styles.listViewContainer}`}>
        <TableView inventories={inventories} loading={loading} />
      </div>
      {inventories.length > 0 && (
        <PaginationComponent count={filterPage.pageCount} page={moveNextPage} />
      )}
    </div>
  );
};

// eslint-disable-next-line react/display-name
export const Dashboard = memo(function (props) {
  return (
    <DashboardProvider>
      <Component {...props} />
    </DashboardProvider>
  );
});
