import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./access-denied.module.scss";

function AccessDenied() {
  const { state } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img
              src={require("@assets/images/no-items-trasperant.png")}
              width='100%'
              alt='no image'
            />
          </div>
          <div className={styles.message}>
            <div className={styles.title}>
              {t(
                "Currently you don`t have permissions to operate with inventories. If this is a mistake, please contact contact@almacenaplatform.com."
              )}
            </div>
            {/* <div className={styles.text}>
              {t("The page you're trying to access has restricted access.")}
            </div>
            <div className={styles.text}>
              {t("If you feel this is a mistake, contact your admin.")}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AccessDenied;
