/**
 * Info Box Component
 * @description: A line of title and description
 */

import Heading from "@atoms/Headings/headings.component";
import { InfoBoxProps } from "@molecules/Info/InfoBox/info-box.types";
import TruncateText from "@molecules/TruncateText/truncate-text.component";
import { ShortInfo } from "@pages/InventoryManagement/inventory-management.type";

import styles from "./info-box.module.scss";
import { formatVolume } from "@lib/helpers/FormatNumber/helpers";

const InfoBox = ({ title, fields, actions }: InfoBoxProps) => {
  const handleDescriptionView = (description: string | number | null) => {
    if (String(description).length === 0 || description === null) {
      return "--";
    } else if (
      typeof description === "string" &&
      description?.includes(".00")
    ) {
      return <>{formatVolume(description)}</>;
    } else if (description && typeof description === "string") {
      return <TruncateText text={description} length={34} />;
    } else {
      return description;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Heading type={"h3"}>{title}</Heading>
        {actions}
      </div>
      <div className={styles.contentWrap}>
        {fields.map((item: ShortInfo) => (
          <div className={styles.inventoryLine} key={item.key}>
            <div className={styles.inventoryTitle}>{item.label}</div>
            <div className={styles.inventoryDesc}>
              {handleDescriptionView(item.value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoBox;
