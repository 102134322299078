/**
 * Search Selector component
 * description: Search inside a selector or a dropdown. Looking for the matching options.
 * @props onChange: on input search change send the value to the parent.
 * @props isDropdownOpen
 * Usage:  <SearchSelector onChange={search} selectOpen={showDropdown} />
 */

import React, {useCallback, useEffect, useState} from "react";

import ClearButton from "@molecules/Buttons/ClearButton/clear-button.component";

import { SearchSelectorProps } from "./search-selector.types"
import styles from "./search-selector.module.scss";

const SearchSelector = ({onChange, isDropdownOpen, placeholder = "Search inside options..."}: SearchSelectorProps) => {
  const [searchSelection, setSearchSelection] = useState('');

  useEffect(() => {
    !isDropdownOpen &&
      setSearchSelection('');
  }, [isDropdownOpen])

  const onChangeHandler = (value: string) => {
    setSearchSelection(value);
    onChange && onChange(value)
  }

  const handleKeyChange = useCallback((e: any) => {
    e.stopPropagation();
    switch (e.key) {
      case "Backspace": {
        onChangeHandler(e.target.value)
        break;
      }
    }
  }, [onChange])

  return (
    <div className={styles.container}>
      <input
        value={searchSelection}
        onChange={(e) => {onChangeHandler(e.target.value)}}
        placeholder={placeholder}
        id={styles['input']}
        onKeyDown={handleKeyChange}
        tabIndex={0}
      />

      {searchSelection.length > 0 &&
        <ClearButton onClick={(e: any) => {onChangeHandler(e.target.value)}} />
      }
    </div>
  )
}

export default SearchSelector;
