import enIcon from "@assets/en_US.png";
import frIcon from "@assets/fr_FR.png";
import deIcon from "@assets/de_DE.png";
import itIcon from "@assets/it_IT.png";
import esIcon from "@assets/es_ES.png";
import almacenaLogo from "@assets/Almacena-Logo.png";
import envelope from "@assets/icons/envelope-no-dot.svg";
import traceability from "@assets/icons/Treceability.svg";
import close from "@assets/icons/Close.svg";
import tempOrigin from "@assets/test-images/shutterstock_1904538037 2.jpg";
import tempGrade from "@assets/test-images/gatatu_new-removebg-preview-230x230 1.jpg";
import almacenaFooterLogo from "@assets/footer-almacena.png";

export const almacenaLogoMain: string = almacenaLogo;
export const enFlag: string = enIcon;
export const frFlag: string = frIcon;
export const deFlag: string = deIcon;
export const itFlag: string = itIcon;
export const esFlag: string = esIcon;
export const envelopeIcon: string = envelope;
export const traceabilityIcon: string = traceability;
export const closeIcon: string = close;
export const tempOriginImage: string = tempOrigin;
export const tempGradeImage: string = tempGrade;
export const almacenaFooter: string = almacenaFooterLogo;
