const SendEmail = () => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="16px" height="16px" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#FFF" stroke="none">
                <path d="M399 5036 c-270 -75 -443 -350 -388 -618 6 -29 173 -459 371 -956
l360 -902 -360 -903 c-198 -496 -365 -926 -371 -955 -24 -117 -7 -225 55 -349
40 -79 126 -170 206 -217 107 -63 290 -85 409 -50 45 13 3857 1850 4132 1991
342 176 410 603 138 869 -32 32 -89 71 -149 102 -246 127 -4073 1972 -4121
1986 -65 19 -215 20 -282 2z m2160 -1298 c1081 -523 1986 -961 2011 -974 l45
-23 -1780 0 -1780 -1 -344 862 c-229 572 -346 877 -349 909 -10 104 87 197
192 183 24 -3 799 -372 2005 -956z m2021 -1377 c-19 -10 -926 -450 -2015 -977
-1827 -884 -1984 -958 -2031 -958 -93 -1 -173 78 -174 171 -1 27 98 286 347
910 l348 873 1780 -1 c1735 0 1779 0 1745 -18z"/>
            </g>
        </svg>
    )
}
export default SendEmail