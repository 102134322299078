import { formatNumber } from './format-number.helper';

export function formatPrice(value, symbol) {
  const amount =
    value < 100 && value > 0 ? value.toFixed(2) : formatNumber(Math.round(value));

  if(Number(amount) === 0) {
    return "--"
  }

  return (symbol || "") + amount;
}

export function formatVolume(value, unit) {
  let amount;

  if(unit === "MT") {
    amount = value;
  } else {
    amount = value > 0 ? Math.round(value) : formatNumber(Math.round(value));
  }

  if(Number(amount) === 0) {
    return "--"
  }

  return formatNumber(amount) + (unit || "");
}
