/**
 * Form Select Options
 * @description: used together with React Hook Forms
 * @param
 *
 */

import {useFormContext} from "react-hook-form";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState, forwardRef
} from "react";

import ChevronDownIcon from "@assets/icons/ChevronDown";
import EmptySelect from "@atoms/Fields/EmptySelect/empty-select.component";
import FormInput from "@atoms/Fields/Input/FormInput/form-input.component";
import {
  FormSelectOptionsProps,
  OptionTypes
} from "@atoms/Fields/SelectInput/FormSelectOptions/form-select-options.types";
import SearchSelector from "@organisms/SearchSelector/search-selector.component";

import styles from "./form-select-options.module.scss";

const FormSelectOptions = forwardRef((props: FormSelectOptionsProps, ref?) => {
  const {
    selectOptions,
    required,
    placeholder,
    selectValue,
    canClearValue = true,
    icon,
    onSelectedOption,
    label,
    formName,
    errors,
    onChange,
    ...rest
  } = props;
  const { setValue, getValues } = useFormContext();
  const wrapperRef = useRef<any>(null);

  const formProps: any = rest;

  const [showDropdown, setShowDropdown] = useState(false);
  const [highlightedEl, setHighlightedEl] = useState(-1);
  const [options, setOptions] = useState<OptionTypes[]>([]);
  const [filteredOptions, setFilteredOptions] = useState(selectOptions); // needed for the select search
  const [inputValue, setInputValue] = useState("");

  //init
  useEffect(() => {
    setOptions(selectOptions)
    selectOptions.map((option: OptionTypes) => {
      if(option.key === formProps.value) {
        setInputValue(option.value);
      }
    })
    setFilteredOptions(selectOptions)
  }, [selectOptions])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        showDropdown &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showDropdown]);

  useEffect(() => {
    if(formProps.value === "") {
      setInputValue("");
    }
  }, [formProps.value])

  const handleKeyChange = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      e.stopPropagation();
      switch (e.key) {
        case "ArrowDown": {
          if (!showDropdown) setShowDropdown(true);
          if (highlightedEl >= filteredOptions.length - 1) {
            setHighlightedEl(0);
          } else {
            setHighlightedEl(highlightedEl + 1);
          }
          break;
        }
        case "ArrowUp": {
          if (highlightedEl <= 0) {
            setHighlightedEl(filteredOptions.length - 1);
          } else {
            setHighlightedEl(highlightedEl - 1);
          }
          break;
        }
        case "Enter": {
          setShowDropdown(false);
          setValue(formProps.name, filteredOptions[highlightedEl].key || "");
          setInputValue(filteredOptions[highlightedEl].value)
          break;
        }
        case "Escape": {
          setShowDropdown(false);
          setHighlightedEl(-1);
          break;
        }
      }
    },
    [filteredOptions, highlightedEl]
  );

  const search = (value: any) => {
    let arr: any = [];
    options.map((el: any) => {
      if (el.value.toLowerCase().includes(value.toLowerCase())) arr.push(el);
    });

    setFilteredOptions(arr);
  };

  const selectOption = useCallback((event: React.MouseEvent<HTMLDivElement>, option: string | number, value: string) => {
    event && event.stopPropagation();
    setShowDropdown(false);
    setValue(formProps.name, option, { shouldDirty: true, shouldValidate: true });
    setInputValue(value);
    onChange && onChange(option)
  },[]);

  if (options) {
    if(options.length > 0) {
      return (
        <div className={styles.selectorContainer} ref={wrapperRef}>
          <div
            className={`
              ${styles.select} 
              ${getValues(formProps.name) === selectValue ? styles.defaultValue : ""}
            `}
            onClick={() => {setShowDropdown(!showDropdown); setFilteredOptions(options)}}
            onKeyDown={handleKeyChange}
            tabIndex={0}
          >
            <FormInput
              {...rest}
              label={label}
              type={"text"}
              readonly={true}
              placeholder={placeholder}
              required={required}
              errors={errors}
              value={inputValue}
            />
            <div className={styles.actions}>
              <div style={{ transform: showDropdown ? "rotate(180deg)" : "" }}>
                <ChevronDownIcon />
              </div>
            </div>
          </div>
          {showDropdown && (
            <div
              className={styles.dropdown}
              onMouseLeave={() => setHighlightedEl(-1)}
            >
              {options?.length > 9 && (
                <div className={styles.searchWrap}>
                  <SearchSelector
                    onChange={search}
                    isDropdownOpen={showDropdown}
                  />
                </div>
              )}
              {filteredOptions.map((item: OptionTypes, index: number) => (
                <Fragment key={index}>
                  {icon && <img src={icon} alt={"select-icon"} />}
                  <div
                    className={`
                      ${styles.option} 
                      ${highlightedEl === index ? styles.highlight : ""} 
                    `}
                    onClick={(e) => selectOption(e, item.key, item.value)}
                    onMouseEnter={setHighlightedEl.bind(this, index)}
                  >{item.value}</div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <FormInput
          {...rest}
          label={label}
          type={"text"}
          readonly={true}
          placeholder={placeholder}
          disabled={true}
          required={required}
          value={inputValue}
          errors={errors}
        />
      )
    }
  }
  else {
    return (
      <EmptySelect placeholder={"Currently there are no options available."} />
    );
  }
})

FormSelectOptions.displayName = "FormSelectOptions";

export default FormSelectOptions;
