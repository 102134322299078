function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

function generateRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export const nonce = generateRandomString(24);
export const AUTH_ROOT = `${process.env.REACT_APP_AUTH_ROOT}${nonce}`;
export const AUTH_LOGOUT_ROOT = process.env.REACT_APP_AUTH_LOGOUT_ROOT;
export const MY_PROFILE_URL = process.env.REACT_APP_MY_PROFILE_URL;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const ALMACENA_DOMAIN = process.env.REACT_APP_ALMACENA_DOMAIN;
export const EXECUTE_TRADES_URL = process.env.REACT_APP_EXECUTE_TRADES_URL;
export const SIGN_UP_URL = process.env.REACT_APP_SIGN_UP_URL;
export const ORIGIN_URL = process.env.REACT_APP_ORIGIN_URL;
