import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import {ALMACENA_DOMAIN} from "@lib/config/config";
import styles from "./public-footer.module.scss";

const PublicFooter = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerInnerWrap}>
        <div className={styles.linksWrap}>
          <ul className='shortcuts'>
            <li><a href={`${ALMACENA_DOMAIN}/privacy-policy-2/`}>Privacy Policy</a></li>
            <li><a href={`${ALMACENA_DOMAIN}/terms-and-conditions/`}>Terms and Conditions</a></li>
            <li><a href={`${ALMACENA_DOMAIN}/code-of-conduct/`}>Code of Conduct</a></li>
          </ul>
        </div>
        <div className={styles.socialWrap}>
          <a href='#' title="LinkedIn" target="_blank" className={styles.linkIcon}><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href='#' title="Facebook" target="_blank" className={styles.linkIcon}><FontAwesomeIcon icon={faFacebook} /></a>
          <a href='#' title="Instagram" target="_blank" className={styles.linkIcon}><FontAwesomeIcon icon={faInstagram} /></a>
          <a href='#' title="Link" target="_blank" className={styles.linkIcon}><FontAwesomeIcon icon={faYoutube} /></a>
        </div>
        <div className={styles.rightsWrap}>
          <span>© 2022 All Rights Reserved</span>
        </div>
      </div>
    </footer>
  )
}

export default PublicFooter;
