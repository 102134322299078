/**
 * Rich Text Component
 * description: Using Quill with custom toolbar. Had counter and clears the value on submit.
 * @props setNewMessage: to update the parent
 * @props currentMessage: read the value of the parent. Used to clear the value on submit
 */

import { useCallback, useEffect, useState, KeyboardEvent, useRef } from "react";
import ReactQuill from "react-quill";

import { RichTextProps } from "./rich-text.types";

import styles from "./rich-text.module.scss";
import "./rich-text.styles.scss";
import "react-quill/dist/quill.snow.css";

const RichTextComponent = ({
  setNewMessage,
  currentMessage,
  error,
}: RichTextProps) => {
  const [textValue, setTextValue] = useState("");
  const messageLimit = 1000;
  const toolbarOptions = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ link: "url" }],
      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // ['blockquote', 'code-block'],
      // [{'indent': '-1'}, {'indent': '+1'}],
      // [{'direction': 'rtl'}],
      // [{'size': ['small', false, 'large', 'huge']}],
      // [{'color': []}, {'background': []}],
      // [{'align': []}],

      ["clean"],
    ],
  };

  // on SEND clear the value
  useEffect(() => {
    setTextValue(currentMessage);
  }, [currentMessage]);

  const onValueChange = (value: any) => {
    setNewMessage(value);
    setTextValue(value);
  };

  // count the letters
  const currentMessageCount = useCallback(() => {
    const regex = /(<([^>]+)>)/gi; // remove html regex
    const textLength: any = textValue ? textValue.replace(regex, "").length : 0; // remove the html

    return textLength;
  }, [textValue]);

  // don't allow to add more character after the limit
  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (currentMessageCount() >= messageLimit && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.richTextWrap}>
        <ReactQuill
          onKeyDown={handleKeyDown}
          theme='snow'
          value={textValue}
          onChange={onValueChange}
          placeholder={"Enter message here..."}
          modules={toolbarOptions}
          preserveWhitespace={true}
        />
      </div>
      <div className={styles.maxLength}>
        {currentMessageCount()} out of {messageLimit} characters
      </div>
      {error && <div className={styles.error}>{error.message}</div>}
    </div>
  );
};

export default RichTextComponent;
