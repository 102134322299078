/**
 * Inventory Column component
 * description: Used inside the inventory listing. 1 column out of many
 * @props netWeight
 * @props netWeightUnit
 * @props originCountry
 * @props name
 * @props number
 */

import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

import { inventoryManage } from "@base/globalVariables/page-links";
import Heading from "@atoms/Headings/headings.component";

import {InventoryColumnProps} from "./inventory-column.types";
import styles from "./inventory-column.module.scss";

const InventoryColumn = (
  {
    netWeight,
    netWeightUnit,
    name,
    number,
    producer,
    icoNumber,
    inventoryId
  }: InventoryColumnProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Heading type={"h3"}>
        <Link to={`${inventoryManage}/${inventoryId}`} title={`${t("Inventory Preview")} ${name}`}>
          <span>
            {Math.trunc(Number(netWeight))}{netWeightUnit} {name}
          </span>
        </Link>
      </Heading>
      {!icoNumber && number &&
        <span className={styles.eyebrow}>{number} |</span>
      }
      {producer &&
        <span className={styles.eyebrow}> {producer} </span>
      }
      {icoNumber &&
        <span className={styles.eyebrow}>| {icoNumber}</span>
      }
    </div>
  )
}

export default InventoryColumn;
