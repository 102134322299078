import { httpClient } from "@lib/services/httpClient";
import { API_ROOT } from "@lib/config/config";

import { IGetMessages, MessagesServiceProps } from "./messages-service.types";

const messagesService = {
  postMessage: function (data: any) {
    return httpClient.post(`${API_ROOT}/api-inventory/api-message`, data, {});
  },
  getMessages: function (params: MessagesServiceProps) {
    return httpClient.get<IGetMessages>(
      `${API_ROOT}/api-inventory/api-message`,
      {
        params,
      }
    );
  },
  deleteMessage: function (id: number) {
    return httpClient.delete(`${API_ROOT}/api-inventory/api-message/${id}`, {});
  },
};

export default messagesService;
