import React, { useEffect, useState, memo } from "react";

import { hideShowPaginationButton } from "@lib/reusable-variables";
import messagesService from "@lib/services/MessagesService/messages-service";
import Heading from "@atoms/Headings/headings.component";
import Button from "@atoms/Button/button.component";
import { ConversationMessagesTypes } from "@molecules/ConversationMessages/conversation-messages.types";
import ConversationMessages from "@molecules/ConversationMessages/conversation-messages.component";

import { MessageListProps } from "./message-list.types";
import styles from "./message-list.module.scss";
import SwitchComponent from "@molecules/Switch/switch.component";

const MessagesList = ({ type, refresh, inventoryId }: MessageListProps) => {
  const [messagePageItems, setMessagePageItems] = useState(0);
  const [messagesTotalCount, setMessagesTotalCount] = useState(0);
  const [messages, setMessages] = useState<ConversationMessagesTypes[]>([]);
  const [counter, setCounter] = useState(0);
  const [hideSystemMessages, setHideSystemMessages] = useState(false);

  //On RichText Submit
  useEffect(() => {
    if (refresh > counter) {
      setCounter(refresh);
      getMessages();
    }
  }, [refresh]);

  //On toggle switch
  useEffect(() => {
    getMessages();
  }, [hideSystemMessages]);

  const getMessages = () => {
    const params = {
      inventoryId: inventoryId ? inventoryId : null,
      pageSize: messagePageItems + 5,
      page: 1,
      hideSystem: hideSystemMessages ? 1 : 0,
    };

    messagesService
      .getMessages(params)
      .then((res) => {
        setMessages(res.data.messages);
        setMessagePageItems(res.data._meta.perPage);
        setMessagesTotalCount(res.data._meta.totalCount);
      })
      .catch((e) => {
        //TODO error handling
      });
  };

  // hide or show the Load More button.
  const showLoadMore = () => {
    if (
      messagesTotalCount > hideShowPaginationButton &&
      messagePageItems < messagesTotalCount
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {messages && type === "conversation" && (
        <div className={styles.messagesContainer}>
          <div className={styles.headerWrap}>
            <Heading type={"h3"}>Messages</Heading>
            <SwitchComponent
              message={"Hide system messages"}
              trigger={(value: boolean) => {
                setHideSystemMessages(value);
              }}
            />
          </div>

          <div>
            {messages?.length > 0 &&
              messages?.map((message: ConversationMessagesTypes, i: number) => {
                return (
                  <ConversationMessages
                    message={message}
                    key={i}
                    host={messages[0].userName}
                  />
                );
              })}
          </div>
          {showLoadMore() && (
            <div className={styles.loadMoreMessagesWrap}>
              <Button type={"text"} onClick={() => getMessages()}>
                Load more
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(MessagesList);
