/**
 * Status Column component
 * description: Used inside the inventory listing. 1 column out of many
 * @props status
 */

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WindowContext } from "@context/window-resize.context";
import { tabletMediaQuery } from "@base/globalVariables/global.variables";
import Button from "@atoms/Button/button.component";

import styles from "./status-column.module.scss";
import { StatusColumnProps } from "./status-column.types";

const StatusColumn = ({ status }: StatusColumnProps) => {
  const { clientWidth } = useContext(WindowContext);
  const { t } = useTranslation();

  return (
    <div className={styles.btnWrap}>
      {clientWidth <= tabletMediaQuery && (
        <span className={styles.title}>{t("Status")}:</span>
      )}
      <span className={styles.info}>
        <Button
          type={status?.value}
          onClick={() => console.log("button clicked")}>
          {status?.value}
        </Button>
      </span>
    </div>
  );
};

export default StatusColumn;
