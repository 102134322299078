import { Cookies } from "react-cookie";
import { httpClient } from "./httpClient";
import i18next from "i18next";
import en from "../i18n/en.json";
const cookies = new Cookies();

export type LanguageType = "en" | "fr" | "es";

const localizationService = {
  getLocalization: function (language: LanguageType) {
    const keys = Object.keys(en);
    return httpClient
      .post(
        `api-common/localization`,
        { keys, cultures: [language] },
        {
          headers: {
            "Accept-Language": language || "en",
          },
        }
      )
      .then((response) => {
        const serverLanguage = response.data?.resources?.[language];
        localStorage.setItem(language, JSON.stringify(serverLanguage));
        // localStorage.setItem('language', language);

        return serverLanguage;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  changeLanguage: function (language: LanguageType) {
    i18next.changeLanguage(language);
    localStorage.setItem("language", language);
    cookies.set("i18next", language);
    const savedLanguage = localStorage.getItem(language);

    // Getting localization is needed for a backend to know
    // the last selected language so that it translates messages dynamically
    // and sends emails in the proper language.
    // There is a check if has stored language in order to avoid double requesting the endpoint because
    // once has called when i18next is initializing
    if (savedLanguage) {
      return this.getLocalization(language).then(() =>
        window.location.reload()
      );
    }

    // Reloading the window is needed for translated the system messages
    return window.location.reload();
  },
  clearDictionary: function () {
    localStorage.removeItem("en");
    localStorage.removeItem("fr");
  },
};

export default localizationService;
