import { ALMACENA_DOMAIN } from "@lib/config/config";
import SendEmail from '@assets/icons/SendEmail';
import styles from './footer.module.scss';
import {almacenaFooter} from "@base/icons/icons.loader";

function Footer() {
    return (
        <footer>
            <div className={styles.footerContainer}>
                <div className={styles.upperFooter}>
                    <section className={styles.linksContainer}>
                        <div className={styles.links}>
                            <div className={styles.getToKnow}>
                                <h4>Get to know about us</h4>
                                <a href={`${ALMACENA_DOMAIN}/how-it-works/`}>How Almacena Works</a>
                                <a href={`${ALMACENA_DOMAIN}/about-us/`}>About Us</a>
                            </div>
                            <div className={styles.becomePartner}>
                                <h4>Become a partner</h4>
                                <a href={`${ALMACENA_DOMAIN}/become-a-partner/`}>Almacena Africa</a>
                                <a href={`${ALMACENA_DOMAIN}/become-a-partner/`}>Almacena Europe</a>
                                <a href={`${ALMACENA_DOMAIN}/become-a-partner/`}>Almacena Finance</a>
                                <a href={`${ALMACENA_DOMAIN}/become-a-partner/`}>Almacena Services</a>
                            </div>
                        </div>
                        <div className={styles.emailContainer}>
                            <h3>You like us?</h3>
                            <p>Subscribe to our Newsletter and take 5% discount!</p>
                            <div className={styles.inputContainer}>
                                <input placeholder='Email address' />
                                <SendEmail />
                            </div>
                        </div>
                        <p className={styles.legalInformation}>
                            <strong>Legal Information: </strong> VAT: NL859035906B01 CoC: 72224193
                        </p>
                    </section>
                    <section className={styles.contacts}>
                        <img className={styles.logo} src={almacenaFooter} alt='logo' />
                        <p className={styles.uppercase}>CONTACTS</p>
                        <a className={styles.phoneNumber} href="tel:+31-20-369-0944">+31 20 369 0944</a>
                        <a className={styles.email} href="mailto: contact@almacenaplatform.com">contact@almacenaplatform.com</a>
                        <p className={styles.bold}>Address:</p>
                        <div className={styles.address}>
                            <p>Prof. J.H. Bavincklaan 7. 1183 AT</p>
                            <p>Amstelveen, The Netherlands</p>
                        </div>
                    </section>
                </div>
                <div className={styles.bottomContainer}>
                    <p className={styles.rights}>© 2022 All Rights Reserved</p>
                    <div className={styles.rightsLinks}>
                        <a href={`${ALMACENA_DOMAIN}/privacy-policy-2/`}>Privacy Policy</a>
                        <a href={`${ALMACENA_DOMAIN}/terms-and-conditions/`}>Terms and Conditions</a>
                        <a href={`${ALMACENA_DOMAIN}/code-of-conduct/`}>Code of Conduct</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
