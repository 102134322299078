import React from "react";
import { createPortal } from "react-dom";

import { PortalProps } from "./portal.types";

const Portal = ({ children, overlay = "overlay" }: PortalProps) => {
  const setOverlay = () => {
    switch (overlay) {
      case "overlay": {
        return "overlay-root";
        break;
      }
      case "underlay": {
        return "underlay-root";
        break;
      }
      case "filter": {
        return "filter-portal";
        break;
      }
    }
  };

  return (
    <>
      {createPortal(
        children,
        document.getElementById(setOverlay()) as HTMLDivElement
      )}
    </>
  );
};

export default Portal;
