/**
 * Mini Info component
 * description: Showing only title and desc on the same line
 * @props title: the bolded text
 * @props description
 * Usage: <MiniInfo title={desc.title} description={desc.description} />
 */

import {ProductInfoProps} from "./mini-info.types";
import styles from './mini-info.module.scss'

const MiniInfo = ({title, description}: ProductInfoProps) => {
  return (
    <div className={styles.infoWrap}>
      <span className={styles.title}>{title}: </span>
      <span className={styles.description}>{description}</span>
    </div>
  )
}

export default MiniInfo;
