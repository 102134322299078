import { forwardRef } from "react";
import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";

import InputLabel from "@atoms/Fields/InputLabel/input-label.components";

import "./mui-outlined-input.styles.scss";

interface MuiOutlinedInputProps extends OutlinedInputProps {
  label?: string;
  required?: boolean;
  end?: any;
  start?: any;
  defaultValue?: string;
  name?: string;
  helperText?: string;
  textAlign?: string;
  fontWeight?: any;
  arrows?: any;
  error?: any;
  value?: any;
}

const MuiOutlinedInput = forwardRef<JSX.Element, MuiOutlinedInputProps>(
  (
    {
      label,
      required,
      end,
      start,
      helperText,
      textAlign,
      fontWeight,
      arrows,
      error,
      ...props
    },
    ref
  ) => {
    return (
      <div className={"outlined-input-component"}>
        <InputLabel label={label ? label : ""} required={required} />
        <OutlinedInput
          {...props}
          error={!!error}
          className={`${textAlign} ${fontWeight} ${arrows ? "arrows" : ""}`}
          inputRef={ref}
          id='outlined-adornment-weight'
          startAdornment={
            <InputAdornment position='start'>{start}</InputAdornment>
          }
          endAdornment={<InputAdornment position='end'>{end}</InputAdornment>}
          aria-describedby='outlined-weight-helper-text'
          inputProps={{
            "aria-label": "weight",
          }}
          value={props?.value === null ? "" : props.value}
        />
        {!!error && (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        )}
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </div>
    );
  }
);

MuiOutlinedInput.displayName = "MuiOutlinedInput";

export default MuiOutlinedInput;
