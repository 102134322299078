/**
 * Initials Helper
 * description: get the initials of a name and return the result.
 */

const getNameInitials = (name: string) => {
  const initials = name?.match(/\b(\w)/g);

  return (initials && initials.join(""))
}

export default getNameInitials;