/**
 * SubHeader Template
 * description: template used inside the header template
 * Usage: <SubHeader />
 */

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";

import { WindowContext } from "@context/window-resize.context";
import Button from "@atoms/Button/button.component";
import SearchInput from "@organisms/SearchInput/search-input.component";
import NotificationMessages from "@organisms/Messages/MessagesList/NotificationMessages/notification-messages.component";

import styles from "./sub-header.module.scss";
import { LocationState, SubHeaderProps } from "./sub-header.types";
import { UserContext } from "@context/UserContext/user.context";

function SubHeader({ searchInput, option }: SubHeaderProps) {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [filterOption, setFilterOption] = useState(
    (state as LocationState)?.filterOption || "Keywords"
  );
  const [filter, setFilter] = useState((state as LocationState)?.filter || "");
  const [archivedDeals, setArchivedDeals] = useState(false);
  const [contentType, setContentType] = useState("");

  const searchOptions = ["Keywords", "Vendor"];

  useEffect(() => {
    if (pathname) {
      setContentType(pathname);
    }
  }, [pathname]);

  const changeFilter = (filter: string) => {
    setFilterOption(filter);
  };

  const search = () => {
    navigate("/", {
      state: {
        filterOption: filterOption,
        filter: filter,
        showArchivedDeals: archivedDeals,
      },
    });
  };

  // when you try to search or change the option of the dropdown
  useEffect(() => {
    if (searchInput) setFilter(searchInput);

    if (option) setFilterOption(option);
  }, [searchInput, option]);

  return (
    <div className={styles.container}>
      <section className={styles.newInventory}>
        <div className={styles.publicLinkWrap}>
          {user &&
            !user.isPendingUser &&
            Object.keys(user).length !== 0 &&
            !user.isUser && (
              <span
                className={`${
                  contentType === "/inventory-dashboard"
                    ? styles.activeLink
                    : ""
                }`}>
                <Link
                  to={"/inventory-dashboard"}
                  title={"Inventory Management"}>
                  Inventory Management
                </Link>
              </span>
            )}
          {/* <span className={`${contentType === '/' ? styles.activeLink : ""}`}>
            <Link to={"/"} title={"Public Inventory"}>
              Public Inventory
            </Link>
          </span> */}
        </div>
        {user && !user.isPendingUser && Object.keys(user).length !== 0 && (
          <>
            <div className={styles.addNewBtnWrap}>
              <Button
                type='empty'
                size='medium'
                btnTextSize='large'
                onClick={() => {}}>
                <Link to={"/add-inventory"}>ADD NEW</Link>
              </Button>
            </div>
            {(location.pathname === "/" ||
              location.pathname === "/inventory-dashboard") && (
              <div className={styles.envelopeWrap}>
                <NotificationMessages />
              </div>
            )}
          </>
        )}
      </section>
    </div>
  );
}

export default SubHeader;
