import React, { memo } from "react";
import Button from "@atoms/Button/button.component";
import Portal from "@molecules/Portal/portal.component";
import DialogComponent from "@organisms/Dialog/dialog.component";
import { useTranslation } from "react-i18next";

type MarkAsSoldOutDialogProps = {
  opened: boolean;
  inventoryName: string;
  onClose: (value: any) => void;
  onSubmit: () => void;
};

const Component = ({
  opened,
  onClose,
  onSubmit,
  inventoryName,
}: MarkAsSoldOutDialogProps) => {
  const { t } = useTranslation();
  const dialog = {
    title: t("MarkAsSoldOutTitle", {
      inventoryName,
    }),
    actions: (
      <Button type={"fill"} size={"small"} onClick={onSubmit} disabled={false}>
        {t("Confirm")}
      </Button>
    ),
    content: <div>{t("MarkAsSoldOutBody")}</div>,
  };
  return (
    <Portal overlay={"overlay"}>
      {opened && (
        <DialogComponent {...dialog} closeDialog={onClose} open={opened} />
      )}
    </Portal>
  );
};

export const MarkAsSoldOutDialog = memo(Component);
