/**
 * Empty Select component
 * description: Show disabled input placeholder when there is no options
 * @props: placeholder
 * Usage: <EmptySelect placeholder={'Currently there are no options available.'} />;
 */

import {useTranslation} from "react-i18next";

import styles from './empty-select.module.scss';

const EmptySelect = ({placeholder}: {placeholder: string}) => {
  const { t } = useTranslation();

  return (
    <input placeholder={t(placeholder)} className={styles.emptySelect} disabled={true} />
  )
}

export default EmptySelect;
