/**
 * Number with commas
 * description: add commas on a number to separate a 1/1000 and add 2 numbers after decimal= 14,999,999.55
 * @props number
 * Usage: formatNumber(number)
 */

export function formatNumber(number: string | number) {
  if(number) {
    if(String(number).includes(',')) {
      return number;
    }

    const formattedNumber = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedNumber;
  }
  return '';
}
