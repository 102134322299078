/**
 * Delete Button component
 * description: Delete button that sends to the parent when it is clicked.
 * @props onClick: send signal to the parent when it is clicked.
 * Usage:  <DeleteButton onClick={() => onClose()} />
 */

import DeleteIcon from "@assets/icons/Trashcan";
import { MouseEvent } from "react";

import styles from "./delete-button.module.scss";

type Props = {
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
};
const DeleteButton = ({ onClick }: Props) => {
  return (
    <div onClick={onClick} className={styles.removeButtonWrap}>
      <DeleteIcon height={16} width={16} />
    </div>
  );
};

export default DeleteButton;
