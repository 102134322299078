import { Cookies } from "react-cookie";
import axios, { AxiosError } from "axios";

import {
  API_ROOT,
  AUTH_LOGOUT_ROOT,
  AUTH_ROOT,
  nonce,
} from "@lib/config/config";
import { vendorTraceability } from "@base/globalVariables/page-links";

const cookies = new Cookies();
const API_URL = `${API_ROOT}`;

export const isAuth = () =>
  cookies.get("id_token") ||
  cookies.get("nonce") ||
  cookies.get("access_token");

export const userAuthorize = () => {
  cookies.set("requestedURL", window.location.pathname, { path: "/" });
  cookies.set("nonce", nonce, { maxAge: 3600, path: "/" });
  window.location.replace(AUTH_ROOT);
};

export const checkCookies = () => {
  if (
    (!cookies.get("id_token") ||
      !cookies.get("nonce") ||
      !cookies.get("access_token")) &&
    window.location.pathname !== "/" &&
    window.location.pathname !== vendorTraceability
  ) {
    userAuthorize();
  }
};

export const userLogout = () => {
  const idToken = cookies.get("id_token");

  cookies.remove("id_token");
  cookies.remove("access_token");
  cookies.remove("nonce");
  cookies.remove("user");
  localStorage.clear();
  //localStorage.removeItem('userInfo');

  window.location.replace(`${AUTH_LOGOUT_ROOT}${idToken}`);
};
export const httpClient = axios.create({
  baseURL: API_URL,
});

httpClient.interceptors.request.use((req) => {
  checkCookies();
  req.headers!.Authorization = `Bearer ${cookies.get("id_token")}`;
  req.headers!["Custom-Nonce"] = cookies.get("nonce");
  req.headers!["Accept-Language"] = localStorage.getItem("i18nextLng") || "en";

  return req;
});

httpClient.interceptors.response.use(
  (res) => {
    return res;
  },
  (error: AxiosError) => {
    if (error?.response?.status === 403) {
      window.location.href = "/error/403";
    } else if (error?.response?.status === 404) {
      window.location.href = "/error/404";
    } else if (error?.response?.status === 401) {
      // cookies.remove('id_token');
      // cookies.remove('access_token');
      // cookies.remove('nonce');
      // cookies.remove('user');
      // userAuthorize();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
