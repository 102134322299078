/**
 * Initials component
 * description: Styled Initials of a name. Taking the first letters of the 2 words
 * @props name: the initials
 * @props name: background of the circle
 * Usage: <Initials name={message.name} color={message.user === 'client' ? '#D73964' : '#D2E4E6'}/
 */

import getNameInitials from "@lib/helpers/NameInitials/get-name-initials.helper";

import {InitialsProps} from "./initials.types";
import styles from './initials.module.scss';

const Initials = ({name, color, initialsStyles}: InitialsProps) => {
  return (
    <div className={`${styles.initialsWrap} ${initialsStyles}`} style={{'backgroundColor': color}}>
      <div>{getNameInitials(name)}</div>
    </div>
  )
}

export default Initials;
