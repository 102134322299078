import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import handleErrorsHelper from "@lib/helpers/HandleApiErrors/handle-api-errors.helper";
import { User } from "@lib/types/user.type";
import inventoryService from "@lib/services/inventory-service";
import Heading from "@atoms/Headings/headings.component";
import MiniInfo from "@atoms/MiniInfo/mini-info.components";
import Button from "@atoms/Button/button.component";
import FormTextarea from "@atoms/Fields/Textarea/FormTextarea/form-textarea.component";
import { AlertTypes } from "@molecules/Alert/alert.types";
import Alert from "@molecules/Alert/alert.component";
import ConfirmationPopup from "@molecules/ConfirmationPopup/confirmation-popup.component";
import {
  InfoBlock,
  ShortInfo,
} from "@pages/InventoryManagement/inventory-management.type";
import { PopupUpdateInventoryProps } from "./popup-update-inventory.types";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const PopupUpdateInventory = ({
  lotId,
  updateType,
  documentItem,
  previewInformation,
  updateHandler,
}: PopupUpdateInventoryProps) => {
  const { t } = useTranslation();
  const { id: previewId } = useParams();

  const [error, setError] = useState<any>();
  const [alert, setAlert] = useState<AlertTypes>({} as AlertTypes);
  const [user_, setUser] = useState<User>({} as User);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [inventoryTitle, setInventoryTitle] = useState("");
  const [producerTitle, setProducerTitle] = useState("");

  // Form Init
  const form = useForm();
  const { register, getValues, setValue, watch } = form;
  const formName = "UpdateInventory";

  const getNameAndProducer = (fields: Array<InfoBlock>) => {
    let inventoryName: string | number = "";
    let producer: string | number = "";

    fields.forEach((section: InfoBlock) => {
      if (section.title === "Inventory Information") {
        section.fields.forEach((field: ShortInfo) => {
          if (field.key === String("name")) {
            inventoryName = field.value ? field.value : "";
          } else if (field.key === String("producerId")) {
            producer = field.value ? field.value : "";
          }
        });
      }
    });

    if (updateType === "Approve") {
      setInventoryTitle(
        t("You are about to approve ") + inventoryName + " by " + producer
      );
    } else {
      if (documentItem?.name) {
        const title = t("DeclineDocument", {
          producer,
          name: documentItem?.name,
        });
        setInventoryTitle(title);
      } else {
        const title = t("DeclineInventory", {
          producer,
          name: inventoryName,
        });
        setInventoryTitle(title);
      }
    }

    setProducerTitle(producer);
  };

  //On Init
  useEffect(() => {
    getNameAndProducer(previewInformation);
  }, [previewInformation]);

  // const getPreview = useCallback(() => {
  //   if (previewId) {
  //     inventoryService
  //       .getInventoryPreview(previewId)
  //       .then(({ data }) => {
  //         setUser(data.user); // User
  //         getNameAndProducer(data.single_model[0].inventory_preview); // Get the Title name of the page.
  //       })
  //       .catch((e) => {
  //         handleErrorsHelper(e.response.data, setError, setAlert);
  //       });
  //   }
  // }, [previewId]);

  return (
    <>
      {updateType === "Approve" ? (
        <Button
          type={"fill"}
          size={"small"}
          onClick={() => setUpdatePopup(true)}>
          <HighlightOffIcon />
          {updateType}
        </Button>
      ) : (
        <Button
          type={"decline"}
          size={"small"}
          onClick={() => setUpdatePopup(true)}>
          <HighlightOffIcon />
          {updateType}
        </Button>
      )}

      {updatePopup && (
        <ConfirmationPopup
          agreeText={updateType === "Decline" ? "Decline" : "Approve"}
          isOpen={updatePopup}
          title={inventoryTitle}
          setIsOpen={setUpdatePopup}
          cancellationHandler={() => {
            setUpdatePopup(false);
          }}
          agreementHandler={() => {
            updateHandler(lotId, getValues(formName));
            setUpdatePopup(false);
          }}
          disableConfirmButton={
            updateType === "Decline" ? (watch(formName) ? false : true) : false
          }>
          <FormProvider {...form}>
            <div
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(formName, e.target.value)
              }>
              {/* <Heading type={"h4"}>{inventoryTitle}</Heading> */}

              {updateType === "Decline" ? (
                <MiniInfo
                  title={t("An Email with the applied reason will be sent to")}
                  description={producerTitle}
                />
              ) : (
                <MiniInfo
                  title={t("Approval email will be sent to")}
                  description={producerTitle}
                />
              )}

              {updateType === "Decline" && (
                <div {...register(formName)}>
                  <FormTextarea
                    placeholder={`${t("Reason for Declining")}...`}
                  />
                </div>
              )}
            </div>
          </FormProvider>
        </ConfirmationPopup>
      )}
    </>
  );
};

export default PopupUpdateInventory;
