import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const useDashboardContext = () => {
  const context = useContext(dashboardContext);
  if (!context) {
    throw new Error(
      "DashboardContext must be used within a useDashboardProvider"
    );
  }
  return context;
};

const dashboardContext = createContext<{ [key: string]: any }>({});

export const DashboardProvider = ({ children }: any) => {
  const [filterPage, setFilterPage] = useState({
    currentPage: 0,
    pageCount: 0,
    perPage: 10,
    totalCount: null,
  });
  const [filterColumns, setFilterColumns] = useState({});
  const [columns, setColumns] = useState([
    "inventory",
    "quantity",
    "location",
    "status",
    "owner",
  ]);
  const [filterColumnsArray, setFilterColumnsArray] = useState<any[]>([]);

  const setFilterByColumns = useCallback(
    (columnName: string, value: any) => {
      if (value !== "none") {
        if (columnName in filterColumns) {
          setFilterColumns((current) => ({ ...current, [columnName]: value }));
          setFilterColumnsArray((current) => [
            ...current.filter((col) => !col.includes(columnName)),
            `${columnName}:${value}`,
          ]);
        } else {
          setFilterColumnsArray((current) => [
            ...current,
            `${columnName}:${value}`,
          ]);
          setFilterColumns((current) => ({
            ...current,
            [columnName]: value,
          }));
        }
      } else {
        if (columnName in filterColumns) {
          setFilterColumnsArray((current) => [
            ...current.filter((col) => !col.includes(columnName)),
          ]);
          const filter = Object.entries(filterColumns)
            .filter(([key, _]) => key !== columnName)
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {} as { [key: string]: any });
          setFilterColumns(filter);
        }
      }
    },
    [filterColumns, setFilterColumns]
  );

  // on Manage Columns change reset the asc/desc filters
  const cleanFilters = useCallback(() => {
    setFilterColumnsArray([]);
    setFilterColumns({});
  }, [setColumns]);

  const store = useMemo(
    () => ({
      filterPage,
      columns,
      filterColumns,
      filterColumnsArray,
      setColumns,
      setFilterPage,
      setFilterByColumns,
      cleanFilters,
    }),
    [filterPage, columns, setFilterByColumns, filterColumns, filterColumnsArray]
  );

  return (
    <dashboardContext.Provider value={store}>
      {children}
    </dashboardContext.Provider>
  );
};
