/**
 * Horizontal Sliding of a component
 * @description: can scroll horizontally if the component's width is bigger than 100%
 * @function: hide prev and next arrow when you reach one of the ends
 * @function: dont show the arrows if the component is equal to its parent
 * @function: added animation on scrolling
 * @function: update the scrolling dynamically. If the components gets bigger or smaller dynamically
 * @props refParent: gets the width of the parent
 * @props refComponent: gets the width if the component and moves it on scroll
 * @props items: needed in order to check if we need to hide the arrows or not
 * @props loading: when the parent component is loading: hide the arrows
 */
type HorizontalScrollingProps = {
  refParent: RefObject<any>;
  refComponent: RefObject<any>;
  items: any[];
  loading: boolean;
};

import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import PageButtonInteraction from "@templates/PageButtonInteraction/page-button-interaction.template";
import { useDashboardContext } from "@hooks/useDashboard.context";
import { sideScroll } from "@lib/helpers/horizontal-smooth-scrolling.helper";

import styles from "./horizontal-scrolling.module.scss";

const HorizontalScrolling = ({
  refParent,
  refComponent,
  items,
  loading,
}: HorizontalScrollingProps) => {
  const { columns } = useDashboardContext();
  const prevArrowRef = useRef<any>(null);
  const nextArrowRef = useRef<any>(null);

  const [hidePrevPagination, setHidePrevPagination] = useState(false);
  const [hideNextPagination, setHideNextPagination] = useState(false);
  const [hideScrolling, setHideScrolling] = useState(false);

  useEffect(() => {
    hideTableScrolling();
  }, [columns]);

  const hideTableScrolling = useCallback(() => {
    const paperWidth = refComponent.current.clientWidth;
    const tableWidth = refParent.current.clientWidth;

    if (paperWidth < tableWidth) {
      setHideScrolling(false);
    } else {
      setHideScrolling(true);
    }
  }, [columns]);

  const handleTableNext = () => {
    // show prev arrow when you click on the next
    if (hidePrevPagination) {
      setHidePrevPagination(false);
    }

    const distance = 100;
    // Slide with an animation
    const sideScrollValue = sideScroll({
      element: refComponent.current,
      direction: "right",
      speed: 20,
      distance,
      step: 20,
    });

    //Get the total scroll distance
    const maxScrollLeft =
      refComponent.current.scrollWidth - refComponent.current.clientWidth;

    // hide the next arrow
    if (sideScrollValue >= maxScrollLeft - 100) {
      setHideNextPagination(true);
    }
  };

  const handleTablePrev = () => {
    // show next arrow when you click on the prev
    if (hideNextPagination) {
      setHideNextPagination(false);
    }

    setHideNextPagination(false);
    // Slide with an animation
    const sideScrollValue = sideScroll({
      element: refComponent.current,
      direction: "left",
      speed: 20,
      distance: 100,
      step: 20,
    });

    // hide the prev arrow
    if (sideScrollValue <= 100) {
      setHidePrevPagination(true);
    }
  };

  // ON ArrowRight and  ArrowLeft click
  const handleKeyPressed = (keyPressed: any) => {
    if (keyPressed) {
      if (keyPressed === "ArrowRight") {
        nextArrowRef.current && nextArrowRef.current.click();
      } else if (keyPressed === "ArrowLeft") {
        prevArrowRef.current && prevArrowRef.current.click();
      }
    }
  };

  return (
    <PageButtonInteraction setKeyPressed={handleKeyPressed}>
      {!hidePrevPagination && items.length > 0 && !hideScrolling && !loading && (
        <div
          className={styles.prevArrow}
          onClick={handleTablePrev}
          ref={prevArrowRef}>
          <div className={styles.arrowWrap}>
            <ArrowBackIosIcon />
          </div>
        </div>
      )}
      {!hideNextPagination && items.length > 0 && !hideScrolling && !loading && (
        <div
          className={styles.nextArrow}
          onClick={handleTableNext}
          ref={nextArrowRef}>
          <div className={styles.arrowWrap}>
            <ArrowForwardIosIcon />
          </div>
        </div>
      )}
    </PageButtonInteraction>
  );
};

export default HorizontalScrolling;
