/**
 * Window Context
 * description: Resize context that has the width and the height of the current window
 * Usage: const { clientWidth, clientHeight } = useContext(WindowContext);
 */

import React, { useCallback, useEffect, useState, createContext } from 'react';

export type WindowContextProps = {
  clientHeight: number;
  clientWidth: number;
};

export const WindowContext = createContext<WindowContextProps>({ clientHeight: 0, clientWidth: 0, });

const WindowContextProvider = ({ children }: any) => {
  const getVh = useCallback(() => {
    return Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  }, []);
  const getVw = useCallback(() => {
    return Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
  }, []);

  const [clientHeight, setVh] = useState<number>(getVh());
  const [clientWidth, setVw] = useState<number>(getVw());

  useEffect(() => {
    const handleResize = () => {
      setVh(getVh());
      setVw(getVw());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getVh, getVw]);

  return (
    <WindowContext.Provider value={{ clientHeight, clientWidth, }}>
      {children}
    </WindowContext.Provider>
  );
};

export default WindowContextProvider;