/**
 * Request Documents for User
 * description: what user see for the Request Documents section.
 */

import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import React, { Fragment, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";

import documentsService from "@lib/services/DocumentsServices/documents-service";
import handleErrorsHelper from "@lib/helpers/HandleApiErrors/handle-api-errors.helper";
import {
  statusPending,
  statusUploaded,
  statusRejected,
} from "@base/globalVariables/global.variables";
import Heading from "@atoms/Headings/headings.component";
import Button from "@atoms/Button/button.component";
import StaticGrid from "@atoms/Grid/StaticGrid/static-grid.component";
import FullScreenLayout from "@atoms/FullScreenLayout/full-screen-layout.component";
import FormDropZone from "@organisms/DropZone/FormDropZone/form-dropzone.component";
import IconTextBtn from "@organisms/IconTextBtn/icon-text-btn.component";

import {
  ChoosenFile,
  RequestDocumentsUserProps,
} from "./request-documents-user.types";
import styles from "./request-documents-user.module.scss";

const RequestDocumentsUser = ({
  allowedFiles,
  maxSize,
  lot,
  requestDocuments,
}: RequestDocumentsUserProps) => {
  const { t } = useTranslation();

  const [isDropZoneOpen, setIsDropZoneOpen] = useState(false);
  const [chosenFile, setChosenFile] = useState<ChoosenFile>({} as ChoosenFile);
  const [alert, setAlert] = useState();

  // Form Init
  const form = useForm();
  const { handleSubmit, register, setError, setValue, getValues, reset } = form;
  const formName = "SendFiles";

  const openDropZone = (id: number, name: string, user: string) => {
    setIsDropZoneOpen(true);
    setChosenFile({
      id: id,
      name: name,
      user: user,
    });
  };

  // When you upload a file change the status of the chip in order to avoid re-render of the whole page.
  const changeDocumentStatus = () => {
    Object.values(requestDocuments).forEach((document: any) => {
      if (document.id === chosenFile.id) {
        document.status = statusUploaded;
      }
    });
  };

  const sendFile = handleSubmit((data) => {
    documentsService
      .uploadDocument(chosenFile.id, data[formName][0])
      .then(() => {
        setIsDropZoneOpen(false);
        changeDocumentStatus();
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setError, setAlert);
      });
  });

  // when you close the Layout reset the form
  useEffect(() => {
    if (!isDropZoneOpen) {
      reset();
    }
  }, [isDropZoneOpen]);

  // Decide if we have to disable the Send button inside the Layout with Drag and Drop.
  const disableSendButton = () => {
    if (getValues(formName) && getValues(formName).length > 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      {Object.keys(requestDocuments).length > 0 && (
        <div className={styles.sendDocContainer}>
          <Heading type={"h3"}>{t("Send Documents")}</Heading>

          <div className={styles.uploadInfo}>
            <div className={styles.uploadInfoTitle}>
              {t("Upload files for")} <span className={"uppercase"}>{lot}</span>
            </div>
            <div className={styles.uploadInfoDesc}>
              {t(
                "Please, upload documents for certification to help confirm your inventory"
              )}
            </div>
          </div>

          <div className={styles.docContainer}>
            {Object.values(requestDocuments).map((item: any) => (
              <div
                className={`${styles.documentWrap} 
                `}
                // ${item.status === statusUploaded ? styles.disableButton : ""}
                key={item.name}>
                <IconTextBtn
                  onClick={() => openDropZone(item.id, item.name, item.user)}
                  headerType={"h4"}
                  btnType={"btn"}
                  btnCategory={"none"}
                  btnSize={"small"}
                  btnText={
                    item.status === statusPending
                      ? "Upload"
                      : item.status === statusRejected
                      ? "Upload new"
                      : ""
                  }
                  hideRemoveButton={true}
                  userRole={false}
                  status={item.status}>
                  {item.name}
                </IconTextBtn>

                {item.fileName && (
                  <div className={styles.documentPreview}>
                    <IconButton
                      onClick={() =>
                        documentsService.getDocument(item.filePath)
                      }>
                      <VisibilityIcon className={styles.viewDocumentIcon} />
                    </IconButton>
                  </div>
                )}
              </div>
            ))}
          </div>

          {isDropZoneOpen && (
            <FullScreenLayout toggle={() => setIsDropZoneOpen(!isDropZoneOpen)}>
              <div className={styles.dropWrap}>
                <div>
                  <FormProvider {...form}>
                    <div>
                      <div {...register(formName)}>
                        <FormDropZone
                          formName={formName}
                          label={`${t("Attach Document")}: ${chosenFile.name}`}
                          maxFiles={1}
                          allowedFormats={allowedFiles}
                        />
                      </div>
                    </div>
                  </FormProvider>
                </div>

                <div className={styles.uploadFileInfo}>
                  <span>{t("You are about to share")}</span>
                  <span>
                    <b>&nbsp;{chosenFile.name}&nbsp;</b>
                  </span>

                  {chosenFile.user && (
                    <span>
                      <span>{t("requested by")}</span>
                      <span>
                        <b>&nbsp;{chosenFile.user}&nbsp;</b>
                      </span>
                    </span>
                  )}
                </div>

                <div className={styles.submitButtonWrap}>
                  <Button
                    type='fill'
                    size={"large"}
                    onClick={sendFile}
                    disabled={disableSendButton()}>
                    {t("Send")}
                  </Button>
                </div>
              </div>
            </FullScreenLayout>
          )}
        </div>
      )}
    </>
  );
};

export default RequestDocumentsUser;
