/**
 * Request Document component
 * description: Request document from the user.
 * @props handleSubmit : where to send to.
 * @props counter: needed to refresh a specific component when submitted
 */

import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import documentsService from "@lib/services/DocumentsServices/documents-service";
import {
  statusApproved,
  statusRejected,
  statusUploaded,
} from "@base/globalVariables/global.variables";
import Button from "@atoms/Button/button.component";
import Heading from "@atoms/Headings/headings.component";
import FormInput from "@atoms/Fields/Input/FormInput/form-input.component";
import TooltipComponent from "@atoms/Tooltip/tooltip.component";
import { AlertTypes } from "@molecules/Alert/alert.types";
import Alert from "@molecules/Alert/alert.component";
import IconTextBtn from "@organisms/IconTextBtn/icon-text-btn.component";
import PopupUpdateInventory from "@organisms/PopupUpdateInventory/popup-update-inventory.component";

import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { RequestDocumentsProps } from "./request-documents-admin.types";
import styles from "./request-documents-admin.module.scss";
import { ApproveDocumentDialog } from "@molecules/Dialogs/ApproveDocumentDialog/approve-document.component";

const RequestDocumentsAdmin = (props: RequestDocumentsProps) => {
  const {
    inventoryId,
    requestDocuments,
    inventoryStatus,
    counter,
    previewInformation,
    handleSubmit,
    isMessageSendable,
    setIsMessageSendable,
    isSendButtonEnabled,
    disabled,
  } = props;

  const { t } = useTranslation();

  const [documents, setDocuments] = useState<string[]>([]);
  const [alert, setAlert] = useState<AlertTypes>();
  const [isButtonMenuVisible, setIsButtonMenuVisible] = useState(true);
  const [isNewDocumentAdded, setIsNewDocumentAdded] = useState(false);
  const [isRequestButtonDisabled, setIsRequestButtonDisabled] = useState(true);
  const [approveDocument, setApproveDocument] = useState<any>(null);

  // Form Init
  const form = useForm();
  const {
    register,
    setError,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = form;
  const formName = "RequestDocuments";
  const pendingButtonStatus = "Pending";
  const ifDocumentsExist = useMemo(() => {
    return (
      documents.length > 0 || Object.keys(requestDocuments.documents).length > 0
    );
  }, [documents, requestDocuments.documents]);

  // Init the Documents
  useEffect(() => {
    const arr: string[] = [];
    Object.entries(requestDocuments.documents).forEach(([key, value]: any) => {
      if (value.status === pendingButtonStatus) {
        arr.push(value?.name);
        delete requestDocuments.documents[key];
      }
    });
    setDocuments(arr);
  }, [requestDocuments]);

  // Add the input value to the document needed for the request documents payload
  const addDocument = () => {
    // check if the name exist in both the requestDocuments{}(coming form the api)
    // and the newly created documents[]
    const documentExists = Object.values(requestDocuments.documents).some(
      (item: any) => {
        return (
          item.name === getValues(formName) ||
          documents.includes(getValues(formName))
        );
      }
    );

    if (documentExists) {
      setError(formName, {
        message: "Document with such name already exists.",
      });
    } else {
      setIsNewDocumentAdded(true);
      setError(formName, { message: "" });
      setDocuments([...documents, getValues(formName)]);
      setValue(formName, "");
    }
    setIsRequestButtonDisabled(false);
  };

  const removeDocument = (children: string) => {
    setIsRequestButtonDisabled(false);
    setDocuments(documents.filter((item: string) => item !== children));
    setIsNewDocumentAdded(true);
  };

  const rejectDocument = (
    version: number,
    buttonType: string,
    rejectReason: string,
    id: number
  ) => {
    documentsService
      .updateDocumentStatus(version, buttonType, rejectReason)
      .then(() => {
        changeDocumentStatus(id);

        //alert for the success
        setAlert({
          state: "success",
          message: "The document has been declined.",
        });
        // needed for the alert to disappear
        setTimeout(() => {
          setAlert(undefined);
        }, 4000);
      });
  };

  const changeDocumentStatus = (id: number) => {
    Object.values(requestDocuments.documents).forEach((document: any) => {
      if (document.id === id) {
        document.status = statusRejected;
      }
    });
  };

  const submitDocuments = () => {
    setIsRequestButtonDisabled(true);

    if (inventoryId) {
      documentsService.requestDocuments(inventoryId, documents).then(() => {
        handleSubmit(counter + 1);
        //alert for the success
        setAlert({
          state: "success",
          message: "Request submitted successfully.",
        });
        // needed for the alert to disappear
        setTimeout(() => {
          setAlert(undefined);
        }, 4000);
      });
    }
  };

  //Avoid repeating of code
  const checkStatus = () => {
    return (
      inventoryStatus !== statusApproved && inventoryStatus !== statusRejected
    );
  };

  // Decide when to show the Reject button, on Document Upload button click
  const isRejectButtonActive = (item: string) => {
    if (
      isButtonMenuVisible &&
      item === statusUploaded &&
      inventoryStatus !== statusApproved &&
      inventoryStatus !== statusRejected
    ) {
      return true;
    }
    return false;
  };

  const isAddButtonDisabled = () => {
    const value = watch(formName);
    if (value?.length > 0 && !documents.includes(value)) {
      return false;
    } else {
      return true;
    }
  };

  const getSingleDocument = (file: any) => {
    return documentsService.getDocument(file);
  };

  const producer = useMemo(() => {
    if (
      !previewInformation ||
      !previewInformation[0] ||
      !previewInformation[0]?.fields
    ) {
      return null;
    }
    return previewInformation[0].fields.find(
      (field: any) => field.key === "producerId"
    )?.value;
  }, [previewInformation]);

  // when u add or remove a document: enable the button
  useEffect(() => {
    if (documents.length > 0 && isNewDocumentAdded) {
      setIsRequestButtonDisabled(false);
      isSendButtonEnabled(false);
    }

    if (disabled) {
      setIsRequestButtonDisabled(true);
      isSendButtonEnabled(false);
    }
  }, [documents]);

  useEffect(() => {
    if (isMessageSendable && !isRequestButtonDisabled) {
      setTimeout(() => {
        submitDocuments();
        setIsMessageSendable(false);
      });
    }
  }, [isMessageSendable, setIsMessageSendable]);

  return (
    <div className={styles.container}>
      <ApproveDocumentDialog
        documentName={approveDocument?.name}
        producer={producer}
        opened={!!approveDocument}
        onClose={() => setApproveDocument(null)}
        onSubmit={() => {
          setApproveDocument(null);
        }}
      />
      <div className={styles.titleWrap}>
        <Heading type={"h3"}>{t("Request Documents")}</Heading>
      </div>

      <div className={styles.content}>
        {checkStatus() && !disabled && (
          <div className={styles.formWrap}>
            <div className={styles.inputWrap}>
              <FormProvider {...form}>
                <div
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setValue(formName, e.target.value)
                  }>
                  <div {...register(formName)}>
                    <FormInput
                      type={"text"}
                      label={"Request a document"}
                      required={true}
                      placeholder={"Describe the requested item"}
                      disabled={disabled}
                      errors={errors[formName]?.message}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
            <div className={styles.btnWrap}>
              <Button
                type={"empty"}
                size={"large"}
                onClick={() => addDocument()}
                disabled={isAddButtonDisabled() || disabled}>
                {t("Add")}
              </Button>
            </div>
          </div>
        )}

        {ifDocumentsExist && (
          <div className={`${styles.documentsWrap}`}>
            {Object.values(requestDocuments.documents).map(
              (item: any, i: number) => (
                <div className={`${styles.documentWrap}`} key={i}>
                  <IconTextBtn
                    onClick={() => setIsButtonMenuVisible(isButtonMenuVisible)}
                    headerType={"h4"}
                    btnType={"btn"}
                    btnCategory={"none"}
                    btnSize={"small"}
                    btnText={""}
                    onClose={(children) => removeDocument(children)}
                    hideRemoveButton={item.status !== pendingButtonStatus}
                    userRole={true}
                    status={item.status}>
                    {item.name}
                  </IconTextBtn>

                  <div className={styles.documentActionButtons}>
                    {item.fileName && (
                      <div className={`${styles.documentAction} ${styles.view}`}>
                        <Button
                          type={"link"}
                          size={"small"}
                          onClick={() => getSingleDocument(item.filePath)}>
                          <VisibilityIcon width={16} height={16} color={"primary"} />
                          {t("View")}
                        </Button>
                      </div>
                    )}

                    {item.fileName &&
                      item?.status !== "Approved" &&
                      inventoryStatus !== statusApproved &&
                      inventoryStatus !== statusRejected && (
                      <div className={`${styles.documentAction} ${styles.approve}`}>
                          <Button
                            type={"link"}
                            size={"small"}
                            onClick={() => setApproveDocument(item)}>
                            <CheckCircleOutlineIcon width={16} height={16} />
                            {t("Approve")}
                          </Button>
                        </div>
                      )
                    }

                    {isRejectButtonActive(item.status) && (
                      <div
                        className={`${styles.documentAction} ${styles.decline}`}>
                        <PopupUpdateInventory
                          previewInformation={previewInformation}
                          lotId={Number(inventoryId)}
                          documentItem={item}
                          updateType='Decline'
                          updateHandler={(
                            inventoryId: number,
                            rejectReason: string
                          ) =>
                            rejectDocument(
                              item.versionId,
                              "rejected",
                              rejectReason,
                              item.id
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )
            )}

            {documents.map((item: string) => (
              <div
                className={`${styles.documentWrap} ${styles.disableButton}`}
                key={item}>
                <IconTextBtn
                  onClick={() => void 0}
                  headerType={"h4"}
                  btnType={"btn"}
                  btnCategory={"none"}
                  btnSize={"small"}
                  btnText={""}
                  onClose={(children) => removeDocument(children)}
                  hideRemoveButton={!checkStatus()}
                  status={"Pending"}>
                  {item}
                </IconTextBtn>
              </div>
            ))}
          </div>
        )}
        <Alert alert={alert}></Alert>
      </div>
    </div>
  );
};

export default RequestDocumentsAdmin;
