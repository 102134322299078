import React, {useContext} from 'react';

import { LoaderContext } from "@context/loader.context";

import styles from './global-loader.module.scss';

const GlobalLoader = () => {
  const { isLoading } = useContext(LoaderContext);

  return (
    <>
      {
        isLoading && (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderWrap}>
              <div className={styles.loader}></div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default GlobalLoader
