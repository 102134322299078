import {useTranslation} from "react-i18next";

import Portal from "@molecules/Portal/portal.component";

import { AlertProps } from "./alert.types";
import "./alert.styles.scss";

function Alert({ alert }: AlertProps) {
  const { t } = useTranslation();

  return (
    <>
      {alert?.message &&
        <Portal overlay={"underlay"}>
          <div className={`alert-popup visible ${alert.state}`}>
            {t(alert.message)}
          </div>
        </Portal>
      }
    </>
  );
}

export default Alert;
