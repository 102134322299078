/**
 * NotificationMessage Item component
 * description: message from a conversation inside User preview
 * @props message
 * @props host: the parent should send the host name in order to place it on the left.
 */

import parse from "html-react-parser";

import variables from "@base/styles/variables.scss";
import Heading from "@atoms/Headings/headings.component";
import Initials from "@atoms/Initials/initials.component";

import { MessageItemProps } from "./conversation-messages.types";
import { Trans, useTranslation } from "react-i18next";

import styles from "./conversation-messages.module.scss";
import TruncateText from "@molecules/TruncateText/truncate-text.component";

const ConversationMessages = ({ message, host }: MessageItemProps) => {
  const isHost = message.userName === host;
  const isSystemMessage = message.type === "system";
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.container} ${
        isHost ? styles.alignLeft : styles.alignRight
      } ${isSystemMessage ? styles.systemMessage : ""} `}>
      <div className={styles.initialsWrap}>
        <Initials
          initialsStyles={isSystemMessage ? styles.systemMessageAvatar : ""}
          name={isSystemMessage ? "" : message.userName}
          color={
            isHost ? variables.primaryBtnBgDark : variables.primaryBtnBgLightBlue
          }
        />
      </div>
      <div className={styles.messageWrap}>
        <div className={styles.message}>
          {isSystemMessage && (
            <Heading type={"h5"}>{t("System Message")}</Heading>
          )}
          <TruncateText
            text={parse(String(message.message)) as string}
            length={90}
          />
        </div>
        <div className={styles.date}>{message.date}</div>
      </div>
    </div>
  );
};

export default ConversationMessages;
