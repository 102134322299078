import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import Button from "@atoms/Button/button.component";
import { useDashboardContext } from "@hooks/useDashboard.context";
import PopupLayout from "@templates/PopupLayout/popup-layout.component";
import CheckboxesChooseArea from "../CheckboxesChooseArea/checkboxes-choose-area.component";

const ManageColumns = () => {
  const { columns, setColumns } = useDashboardContext();
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const checkboxes = [
    {
      name: "owner",
      label: t("Owner"),
    },
    {
      name: "quantity",
      label: t("Quantity"),
    },
    {
      name: "producer",
      label: t("Producer"),
    },
    {
      name: "status",
      label: t("Status"),
    },
    {
      name: "numberOfBags",
      label: t("Number of Bags"),
    },
    {
      name: "bagType",
      label: t("Bags Size"),
    },
    {
      name: "netWeightUnit",
      label: t("Net Weight Units"),
    },
    {
      name: "icoNumber",
      label: t("Invoice No"),
    },
    {
      name: "originCountry",
      label: t("Origin Country"),
    },
    {
      name: "location",
      label: t("Location"),
    },
    {
      name: "region",
      label: t("Regions"),
    },
    {
      name: "altitudeRangeStart",
      label: t("Altitude Range Start"),
    },
    {
      name: "altitudeRangeEnd",
      label: t("Altitude Range End"),
    },
    {
      name: "cropStartDate",
      label: t("Crop Start Date"),
    },
    {
      name: "cropEndDate",
      label: t("Crop End Date"),
    },
    {
      name: "commodityType",
      label: t("Commodity Type"),
    },
    {
      name: "certification",
      label: t("Certifications"),
    },
    {
      name: "otherCertifications",
      label: t("Other Certifications"),
    },
    {
      name: "variety",
      label: t("Variety"),
    },
    {
      name: "process",
      label: t("Process"),
    },
    {
      name: "flavour",
      label: t("Flavour"),
    },
    {
      name: "grade",
      label: t("Grade"),
    },
    {
      name: "taste",
      label: t("Taste"),
    },
    {
      name: "aroma",
      label: t("Aroma"),
    },
  ];

  // Get the Filtered Columns from the LocalStore.
  useEffect(() => {
    if (localStorage.getItem("list-filters")) {
      const storedFilters = localStorage.getItem("list-filters")!.split(",");
      if (storedFilters.length > 0) {
        setColumns(storedFilters);
      }
    }
  }, []);

  return (
    <div>
      <Button
        type={"link"}
        onClick={() => {
          setIsPopupOpen(!isPopupOpen);
        }}>
        {t("Manage Columns")}
      </Button>
      {isPopupOpen && (
        <PopupLayout
          closePopUp={setIsPopupOpen}
          popupTitle={t("Manage Columns")}>
          <CheckboxesChooseArea
            items={checkboxes}
            setResult={setColumns}
            result={columns}
          />
        </PopupLayout>
      )}
    </div>
  );
};

export default ManageColumns;
