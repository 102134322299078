/**
 * Header With Btn Component
 * description: Header with an image or a button at the end.
 * @props resolution: below which resolution you need to show the close button,
 * @props toggle: on button click send to the parent.
 * @props children: the text of the header
 * @props headerType
 * @props image
 * @props imageTitle
 * @props btnType: type of the button default value is 'icon'. Needed to differentiate between icon or btn
 * @props btnCategory: value need for the Button Component
 * Usage: <HeaderButton onClick={() => openToggle} resolution={1024}>Manage Columns</HeaderButton>
 */

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WindowContext } from "@context/window-resize.context";
import { closeIcon } from "@base/icons/icons.loader";
import Heading from "@atoms/Headings/headings.component";
import Button from "@atoms/Button/button.component";

import styles from "./header-with-btn.module.scss";
import { HeaderWithBtnProps } from "./header-with-btn.type";

const HeaderButton = ({
  onClick,
  resolution,
  children,
  headerType = "h2",
  image = closeIcon,
  imageTitle = "close btn",
  btnType = "icon",
  btnCategory,
  btnSize = "medium",
  btnTextSize = "small",
  btnText,
  status,
  userRole,
}: HeaderWithBtnProps) => {
  const { clientWidth } = useContext(WindowContext);
  const { t } = useTranslation();

  //Avoid repeating code.
  const renderBtn = () => {
    if (btnType === "icon") {
      return <img title={imageTitle} src={image} onClick={() => onClick()} />;
    } else if (btnType === "btn") {
      return (
        <Button
          type={btnCategory}
          onClick={(e) => onClick(e)}
          size={btnSize}
          btnTextSize={btnTextSize}>
          {btnText && t(btnText)}
        </Button>
      );
    }
  };

  return (
    <div
      className={`${styles.title} ${
        userRole ? styles.buttonAlternative : ""
      } `}>
      <Heading type={headerType}>
        <div className={styles.headerWrap}>
          <span>{children}</span>
          {status && (
            <div className={styles.status}>
              <Button type={status} size={"small"}>
                {status}
              </Button>
            </div>
          )}
        </div>
      </Heading>

      {resolution ? clientWidth < resolution && renderBtn() : renderBtn()}
    </div>
  );
};

export default HeaderButton;
