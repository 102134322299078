/**
 * Truncate Text component
 * @description: Truncate a text and shop a tooltip
 * @param text
 * @param length
 */

import TooltipComponent from "@atoms/Tooltip/tooltip.component";
import { TruncateTextProps } from "./truncate-text.types";
import { useMemo } from "react";

const TruncateText = ({ text, length }: TruncateTextProps) => {
  if (typeof text === "object") {
    return <TooltipComponent tooltipTitle={text}>{text}</TooltipComponent>;
  }
  if (text?.length > length) {
    return (
      <>
        <TooltipComponent tooltipTitle={text}>
          <>{text?.substring(0, length)}...</>
        </TooltipComponent>
      </>
    );
  } else {
    return <>{text}</>;
  }
};

export default TruncateText;
