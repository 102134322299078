/**
 * Form Input
 * description: working with React Hook Form Input
 */

import React, {ForwardedRef, forwardRef} from "react";
import { useTranslation } from "react-i18next";

import InputLabel from "@atoms/Fields/Input/InputLabel/input-label.components";
import FieldError from "@atoms/Fields/FieldError/field-error.component";
import {FormInputProps} from "@atoms/Fields/Input/FormInput/form-input.types";

import styles from "./form-input.module.scss"

const FormInput = forwardRef((props: FormInputProps, ref: ForwardedRef<any>) => {
  const {
    type,
    label,
    placeholder,
    readonly,
    required,
    errors,
    disabled,
    value,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <InputLabel
          label={label ? label : ""}
          required={required}
        />
        <input
          {...rest}
          className={`
            ${styles.field} 
            ${!!errors ? styles.error : ""}
            ${!label ? styles.noLabel : styles.hasLabel}
          `}
          type={type}
          disabled={readonly}
          placeholder={placeholder && t(placeholder)}
          value={value}
          ref={ref}
        />
      </div>
      <div>
        {errors &&
          <FieldError error={errors} />
        }
      </div>
    </>
  )
});

FormInput.displayName = "FormInput";

export default FormInput;
