const sizeConverter = (fileSize: number, decimal: number) => {
  if (!+fileSize)
    return '0 Bytes';

  const k = 1024;
  const dm = decimal < 0 ? 0 : decimal;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(fileSize) / Math.log(k));

  return `${parseFloat((fileSize / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default sizeConverter;
