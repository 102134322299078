/**
 * Name: Button Component
 * Description: button component used inside the platform.
 * Usage example: <Button onClick={submit} type={empty} size={small} />
 * @param size?: the size of the button.
 * @param type?: the type of the button.
 * @param onClick: after button click send to the parent.
 * @param icon?: if the button has icon
 * @param btnTextColor?: if you need to change the text color of the button
 * @param btnTextSize: if you need to change the size of the text.
 * @todo make it also a Link if we need it
 */

import { ButtonProps, ButtonSize } from "./button.types";
import StyledButton from "./button.styles";
import { ButtonProps as MaterialButtonProps } from "@mui/material/Button";

const Button = (props: ButtonProps) => {
  const {
    children,
    size = "medium",
    type = "fill",
    btnTextSize = "normal",
    icon,
    btnTextColor,
    href,
    disabled,
    btnBg,
    ...restProps
  } = props;
  const typeClass = type.replace(" ", "-").toLowerCase(); //the correct format
  const buttonProps: MaterialButtonProps = restProps;

  return (
    <StyledButton
      {...buttonProps}
      variant='outlined'
      disabled={disabled}
      className={`
        button-size--${size} 
        button-type--${typeClass} 
        text-size--${btnTextSize} 
        ${icon ? "icon-button" : ""} 
        ${btnTextColor ? "text-color--" + btnTextColor : ""}
        ${btnBg ? "btn-bg--" + btnBg : ""}
        ${restProps.onClick ? "" : "disabled-point-events"}
      `}

    >
      {icon && <img src={icon} alt='button icon' className='btn-icon' />}
      {children}
    </StyledButton>
  );
};

export default Button;
