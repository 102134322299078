/**
 * Private Route Component
 * description: This is used to determine if a user is authenticated and
 * if they are allowed to visit the page they navigated to.
 *
 * If they are: they proceed to the page
 * If not: they are redirected to the identity page.
 */

import React, { useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import { AUTH_ROOT, nonce } from "@lib/config/config";
import { UserContext } from "@context/UserContext/user.context";

const PrivateRoute = ({ children }: any) => {
  const [cookies, setCookies] = useCookies();
  // const { openDialog } = useDialogContext();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!cookies["id_token"]) {
      setCookies("requestedURL", window.location.pathname, { path: "/" });
      setCookies("nonce", nonce, { maxAge: 3600, path: "/" });
      window.location.replace(AUTH_ROOT);
      // openDialog({ type: "signIn" });
    } else {
      if (user && (Object.keys(user).length === 0 || user?.isUser)) {
        setCookies("requestedURL", "/", { path: "/" });
      }
    }
  }, []);

  return cookies["id_token"] && children;
};

export default PrivateRoute;
