/**
 * Form Dropzone
 * @description: Dropzone connected with Rect Hook Form
 * @param label
 * @param formName
 * @param allowedFormats
 * @param maxFiles
 */

import React, { Fragment, memo } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import UploadIcon from "@assets/icons/Upload";
import Heading from "@atoms/Headings/headings.component";
import StaticGrid from "@atoms/Grid/StaticGrid/static-grid.component";
import DocumentAttachment from "@organisms/DocumentAttachment/document-attachment.component";
import styles from "@organisms/DropZone/drop-zone.module.scss";

import { FormDropzoneProps } from "./form-dropzone.type";
import FieldError from "@atoms/Fields/FieldError/field-error.component";

const FormDropZone = ({
  label,
  formName,
  allowedFormats,
  maxFiles,
}: FormDropzoneProps) => {
  const { t } = useTranslation();
  const { setValue, getValues, watch } = useFormContext();

  const onDrop = (acceptedFiles: File[]) => {
    setValue(formName, acceptedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    maxFiles: maxFiles,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.ms-word": [".docx"],
    },
    onDrop,
  });

  const removeFile = (name: string) => {
    const remainingFiles = getValues(formName).filter(
      (file: File) => file.name !== name
    );
    setValue(formName, remainingFiles);
  };

  return (
    <div className={styles.uploadFileContainer}>
      {label && <Heading type={"h3"}>{label}</Heading>}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={`${styles.dropzone} ${isDragAccept ? styles.accept : ""} ${
            isDragReject ? styles.reject : ""
          }`}>
          <UploadIcon />
          {isDragActive ? (
            <div className={styles.text}>
              <h5>{t("Drop the files here")} ...</h5>
            </div>
          ) : (
            <div className={styles.text}>
              <h5>
                <Trans
                  i18nKey='Drag and drop file or Browse'
                  components={{ p: <span /> }}
                />
              </h5>
              <p>
                {t("Supported file formats")}:{" "}
                <span className={"lowercase"}>
                  {allowedFormats?.map((format: string, i: number) => {
                    // map the formats
                    if (i < allowedFormats?.length - 1) {
                      return <Fragment key={i}>{format}, </Fragment>;
                    } else {
                      return <Fragment key={i}>{format}</Fragment>;
                    }
                  })}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.error}>
        {/*Translate*/}
        {fileRejections.length > 0 && (
          <FieldError
            error={
              fileRejections[0].errors[0].code === "file-invalid-type"
                ? "The type of the file is incorrect"
                : fileRejections[0].errors[0].message
            }
          />
        )}
      </div>
      {watch(formName)?.length > 0 && (
        <StaticGrid columns={1}>
          {getValues(formName).map((file: any, i: number) => (
            <DocumentAttachment
              fileSize={file.size}
              fileName={file.name}
              key={i}
              onClick={(name: string) => removeFile(name)}
            />
          ))}
        </StaticGrid>
      )}
    </div>
  );
};

export default memo(FormDropZone);
