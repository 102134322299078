/**
 * Notification Message component
 * description: information about the message
 * @props messages: all messages
 * @props isAdmin
 * @props refresh: tell the parent to refresh the messages if needed
 * @props archived
 *
 */

import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DoneIcon from "@mui/icons-material/Done";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import parse from "html-react-parser";

import messagesService from "@lib/services/MessagesService/messages-service";
import DocumentIcon from "@assets/icons/Document";
import DealIcon from "@assets/icons/Deal";
import MessageIcon from "@assets/icons/Message";
import NotificationIcon from "@assets/icons/Notification";
import Close from "@assets/icons/Close";
import ConfirmationPopup from "@molecules/ConfirmationPopup/confirmation-popup.component";
import MessageContent from "@organisms/Messages/MessageContent/message-content.component";
import MessageFiles from "@organisms/Messages/MessageFiles/message-files.component";
import DeleteButton from "@molecules/DeleteButton/delete-button.component";

import { NotificationMessageProps } from "./notification-message.types";
import styles from "./notification-message.module.scss";

function Message({
  message,
  isAdmin,
  refresh,
  archived,
}: NotificationMessageProps) {
  const { t } = useTranslation();

  const [confirmPop, setConfirmPop] = useState(false);

  const deleteMessage = () => {
    messagesService.deleteMessage(message.id).then(() => refresh());
    setConfirmPop(false);
  };

  const Icon = () => {
    switch (message.event) {
      case "Document_Uploaded":
      case "Document_Version_Uploaded":
        return <DocumentIcon className={"document"} />;
        break;
      case "Deal_External_Created":
      case "Deal_RFQ_Created ":
      case "Deal_Created":
        return <DealIcon />;
        break;
      case "Deal_Step_Approved_And_Completed":
      case "Deal_Step_Completed":
        return (
          <CheckCircleOutlineOutlinedIcon
            style={{ backgroundColor: "#7DCA79" }}
          />
        );
        break;
      case "Deal_Step_Rejected":
        return <HighlightOffIcon style={{ backgroundColor: "#E91E63" }} />;
        break;
      case "Deal_Step_Moved_To_Edit":
        return <ModeEditIcon />;
        break;
      case "Deal_Step_Approved":
        return <DoneIcon style={{ backgroundColor: "#7DCA79" }} />;
        break;
      case "Deal_Step_Approval_Submitted":
        return <PendingActionsOutlinedIcon />;
        break;
      case "Deal_Payment_Initiated":
        return <PaymentIcon />;
        break;
      case "Deal_Payment_Failed":
      case "Deal_Payment_Cancelled":
      case "Deal_Payment_Expired":
        return (
          <CreditCardOffOutlinedIcon style={{ backgroundColor: "#E91E63" }} />
        );
        break;
      case "Deal_Payment_Completed":
        return (
          <CreditScoreOutlinedIcon style={{ backgroundColor: "#7DCA79" }} />
        );
        break;
      case "Deal_Payment_Updated":
        return <AddCardOutlinedIcon />;
        break;
      case "Deal_Packing_Slip_Sent":
        return <MailOutlineIcon />;
        break;
      case null:
        return <MessageIcon />;
        break;
      default:
        return <NotificationIcon />;
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.head}>
        <Icon />
        <div className={styles.line}></div>
      </section>
      <section className={styles.mainMessage}>
        <p className={styles.subject}>{parse(String(message.subject))}</p>
        <p className={styles.senderDate}>
          by {message.userName} | {message.date}
        </p>
        <MessageContent message={message} />
        {message.files?.length > 0 && (
          <MessageFiles files={message.files} message={message} />
        )}
      </section>
      {/* {isAdmin && !archived && (
        <DeleteButton
          agreementHandler={() => deleteMessage()}
          buttonTitle={t("Delete message")}
          confirmationTitle={t("Are you sure you want to delete this message?")}
        />
      )} */}
    </div>
  );
}

export default memo(Message);
