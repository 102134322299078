export const userPreview:string = "/user-preview";
export const vendorTraceability:string = "/vendor-traceability";
export const inventoryStatus:string = "/inventory-status";
export const addInventory:string = "/add-inventory";
export const inventoryEdit: string = "/inventory-edit";
export const inventoryArchive:string = "/inventory-archive";
export const adminUserPreview:string = "/admin-user-preview";
export const inventoryDashboard:string = "/inventory-dashboard";
export const inventoryManage:string = "/inventory-manage";

