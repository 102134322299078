/**
 * Dialog Context
 * description: Dialog context
 * Usage: const { openDialog, closeDialog, onOpen, onClose } = useContext(DialogContext);
 */

import SigninDialog from "@organisms/SigninDialog/signin-dialog.component";
import React, {
  useCallback,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";

type DialogType = "signIn";

export type DialogContextProps = {
  openDialog: (params: { type: DialogType; data?: any }) => void;
  closeDialog: () => void;
};

export const useDialogContext = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error(
      "useDialogContext must be used within a DialogContextProvider"
    );
  }
  return context;
};

const DialogContext = createContext<DialogContextProps>(
  {} as DialogContextProps
);
const DialogContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [opened, setOpened] = useState(false);
  const [dialogType, setDialogType] = useState<{
    type: DialogType;
    data: any;
  } | null>(null);

  const openDialog = useCallback(
    ({ type, data }: { type: DialogType; data?: any }) => {
      setDialogType({ type, data });
      setOpened(true);
    },
    []
  );

  const closeDialog = useCallback(() => {
    setOpened(false);
  }, []);

  const Dialog = useMemo(() => {
    const map: { [key in DialogType]: (data?: any) => JSX.Element } = {
      signIn: (data?: any) => (
        <SigninDialog opened={opened} closeDialog={closeDialog} />
      ),
    };
    return dialogType && typeof map[dialogType?.type] === "function" ? (
      map[dialogType.type](dialogType?.data)
    ) : (
      <></>
    );
  }, [closeDialog, dialogType, opened]);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      {/* <DialogComponent {...dialog} closeDialog={closeDialog} open={opened} /> */}
      {Dialog}
    </DialogContext.Provider>
  );
};

export default DialogContextProvider;
