/**
 * Menu Dropdown component
 * Usage:  <MenuDropdown title={userMessage}>{children}</MenuDropdown>
 * @props children: the content of the dropdown
 * @props image: if the title of the dropdown is an image
 * @props title: the text tile of the dropdown
 * @props type: the type of the title. If arrow use downArrow; if Ellipsis use dots
 * @props mouseLeave: if mouseLeave send to the parent
 */

import { useState } from "react";

import ChevronDownIcon from "@assets/icons/ChevronDown";
import Dots from "@assets/icons/Dots";

import { MenuDropdownProps } from "./menu-dropdown.types";
import styles from "./menu-dropdown.module.scss";

const MenuDropdown = ({
  children,
  image,
  title,
  type = "arrow",
  mouseLeave,
  openOnHover,
}: MenuDropdownProps) => {
  const [isShown, setIsShown] = useState(type === "blank" ? true : false);
  const onMouseEnterHandler = () => {
    openOnHover && setIsShown(true);
  };
  const onMouseLeaveHandler = () => {
    setIsShown(false);
  };

  const onClickHandler = () => {
    setIsShown(true);
  };

  return (
    <div
      className={styles.menuTitle}
      onClick={onClickHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}>
      {type === "arrow" && image && image.src && (
        <img src={image.src} alt='image' />
      )}

      {type === "arrow" && title && (
        <p
          style={{ color: isShown ? "#4241DD" : "" }}
          className={styles.dropdownTitle}>
          {title}
        </p>
      )}

      {type === "arrow" && <ChevronDownIcon />}

      {type === "ellipsis" && <Dots />}

      {isShown && (
        <div
          className={`${styles.menuItems} menu-items`}
          onMouseLeave={() => {
            setIsShown(false);
            mouseLeave && mouseLeave();
          }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default MenuDropdown;
