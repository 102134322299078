/**
 * Switch component
 * @description: toggle with text
 * @param message
 * @param trigger: sending the value to the parent
 * @param isChecked: default value of the toggle.
 */

import React, {useState} from "react";
import Switch from "react-switch";

import variables from "@base/styles/variables.scss";

import {SwitchProps} from "./switch.types";
import styles from "./switch.module.scss";

const SwitchComponent = ({message, trigger, isChecked = false}: SwitchProps) => {
  const [checked, setChecked] = useState(isChecked || false);

  return (
    <div className={styles.hideMessages}>
      <p>{message}</p>
      <Switch
        onChange={(value: boolean) => {trigger(value); setChecked(value)}}
        checked={checked}
        onColor={variables.primaryBtnBgLightBlue}
        onHandleColor={variables.primaryBtnTextLight}
        offColor={variables.primaryBtnBgGrey}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={48}
        handleDiameter={13}
      />
    </div>
  )
}

export default SwitchComponent;