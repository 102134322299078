import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import styles from "./checkboxes-choose-area.module.scss";
import { useDashboardContext } from "@hooks/useDashboard.context";

type CheckboxesChooseAreaProps = {
  [key: string]: any;
};

const CheckboxesChooseArea = ({
  items,
  result,
  setResult,
}: CheckboxesChooseAreaProps) => {
  const { register } = useForm();
  const { cleanFilters } = useDashboardContext();

  const [sectionValue, setSectionValue] = useState<any>(result);

  // on checkbox change
  useEffect(() => {
    setResult(sectionValue);
    //Save in local storage
    if (sectionValue) {
      localStorage.setItem("list-filters", `${sectionValue}`);
    }
  }, [sectionValue]);

  const handleChange = (value: any) => {
    // remove from array
    if (sectionValue.includes(value)) {
      setSectionValue((sectionValue: any) =>
        sectionValue.filter((result: any) => result !== value)
      );
      cleanFilters();
    } else {
      // push
      setSectionValue((sectionValue: any) => [...sectionValue, value]);
    }
  };

  return (
    <div className={styles.container}>
      <form>
        {items.map((item: any) => (
          <label key={item.name}>
            <input
              {...register(item.name)}
              type='checkbox'
              value={item.name}
              name='chooseArea'
              checked={result.includes(item.name)}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
            {item.label}
          </label>
        ))}
      </form>
    </div>
  );
};

export default CheckboxesChooseArea;
