import { httpClient } from "@lib/services/httpClient";
import { API_ROOT } from "@lib/config/config";

const documentsService = {
  requestDocuments: function (
    inventoryId: number | string,
    fileNames: string[]
  ) {
    const payload = {
      inventoryId: inventoryId,
      names: fileNames,
    };

    return httpClient.post(
      `${API_ROOT}/api-inventory/request-document`,
      payload
    );
  },

  updateDocumentStatus: function (
    version: number | string,
    buttonType: string,
    rejectReason: string
  ) {
    const payload = {
      buttonType: buttonType,
      rejectReason: rejectReason,
    };

    return httpClient.post(
      `${API_ROOT}/api-inventory/update-document-status/${version}`,
      payload
    );
  },

  uploadDocument: function (documentId: number, file: any) {
    const formData = new FormData();
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    formData.append("file", file);

    return httpClient.post(
      `${API_ROOT}/api-inventory/${documentId}/upload-document`,
      formData,
      {
        headers: headers,
      }
    );
  },

  getDocument: async function (file: any) {
    const allowedTypes = [
      { name: "jpeg", type: "image/jpeg" },
      { name: "png", type: "image/png" },
      { name: "pdf", type: "application/pdf" },
      { name: "doc", type: "application/vnd.ms-word" },
    ];

    return httpClient
      .get(`${API_ROOT}/api-inventory/download/${file}`, {
        responseType: "blob",
      })
      .then((response) => {
        const getCurrentFileType = file.split(".").pop();
        let allowedType;

        allowedTypes.forEach((item) => {
          if (item.name === getCurrentFileType) {
            allowedType = item.type;
          }
        });

        const blob = new Blob([response.data], { type: allowedType }),
          url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        if (getCurrentFileType === "pdf") {
          link.setAttribute("target", file);
        } else {
          link.setAttribute("download", file);
        }

        link.click();
      });
  },
};

export default documentsService;
