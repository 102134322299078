import React, {
  useEffect,
  useState,
  useCallback,
  memo,
  useContext,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import inventoryService from "@lib/services/inventory-service";
import handleErrorsHelper from "@lib/helpers/HandleApiErrors/handle-api-errors.helper";
import { formatVolume } from "@lib/helpers/FormatNumber/helpers";
import { UserContext } from "@context/UserContext/user.context";
import {
  inventoryDashboard,
  inventoryManage,
} from "@base/globalVariables/page-links";
import FormInput from "@atoms/Fields/Input/FormInput/form-input.component";
import FormSelectOptions from "@atoms/Fields/SelectInput/FormSelectOptions/form-select-options.component";
import RadioInputs from "@atoms/Fields/radioInputs/radio-inputs.component";
import FormMultipleSelect from "@atoms/Fields/SelectInput/FormMultiSelect/form-multi-select.component";
import Date from "@atoms/Fields/Date/date-input.component";
import Heading from "@atoms/Headings/headings.component";
import Grid from "@atoms/Grid/DynamicGrid/dynamic-grid.component";
import CancelSubmitBtn from "@molecules/Buttons/CancelSubmitBtn/cancel-submit-btn.component";
import { AlertTypes } from "@molecules/Alert/alert.types";
import Alert from "@molecules/Alert/alert.component";
import LoaderPage from "@templates/PageLoader/page-loader.template";

import { InventoryFormProps } from "./inventory-form.type";
import styles from "./inventory-form.module.scss";
import Button from "@atoms/Button/button.component";

const InventoryForm = ({ isEdit }: InventoryFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: inventoryId } = useParams();
  const { user } = useContext(UserContext);

  const [inventoryFields, setInventoryFields] = useState<any>({});
  const [cuppingNotes, setCuppingNotes] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: any;
  }>({});
  const [alert, setAlert] = useState<AlertTypes>({} as AlertTypes);
  const [loading, setLoading] = useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const form = useForm();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = form;
  const requiredField = t("Field is required");
  const selectPlaceholder = "Select an option";
  const inputTextPlaceholder = "Type";
  const inputNumberPlaceholder = "Enter a number";

  // on init
  useEffect(() => {
    if (Object.keys(inventoryFields).length === 0) {
      const params = { inventoryId } || {};
      const action = isEdit ? "update_inventory" : "create_inventory";
      getFields(action, params);
    }
  }, []);

  const getFields = (
    action: "create_inventory" | "update_inventory",
    params: { [key: string]: any }
  ) => {
    // statement only when you change the Producer manually from the select.
    if (getValues("producerId") && (user?.isAdmin || user?.isManager)) {
      params.producerId = getValues("producerId");
      setValue("productId", "");
    }

    inventoryService
      .getInventoryFields({ action, params })
      .then((res) => {
        setInventoryFields(res.data[0].fields);
        setCuppingNotes(res.data[1].fields);
        setLoading(false);
      })
      .catch((e) => {
        handleErrorsHelper(e.response.data, setValidationErrors, setAlert);
      });
  };

  const submitHandler = useCallback((formData: any) => {
    setDisableSubmitButton(true);

    if (isEdit) {
      inventoryService
        .updateInventory(formData, inventoryId!)
        .then(() => {
          navigate(inventoryDashboard);
          setLoading(false);
        })
        .catch((error) => {
          handleErrorsHelper(
            error.response.data,
            setValidationErrors,
            setAlert
          );
        });
    } else {
      inventoryService
        .createInventory(formData)
        .then(() => {
          navigate(inventoryDashboard, { state: { lotCreated: true } });
          setLoading(false);
          setDisableSubmitButton(false);
        })
        .catch((error) => {
          handleErrorsHelper(
            error.response.data,
            setValidationErrors,
            setAlert
          );
        });
    }
  }, []);

  const cancelHandler = useCallback(() => {
    navigate(`${inventoryManage}/${inventoryId}`);
  }, [navigate, inventoryId]);

  const roleView = () => {
    return user?.isAdmin || user?.isManager;
  };

  // when you change the Producer from the select.
  const getProducts = () => {
    const params = { inventoryId } || {};
    const action = isEdit ? "update_inventory" : "create_inventory";
    getFields(action, params);
  };

  return (
    <LoaderPage isLoading={loading}>
      <Alert alert={alert} />
      <div className={styles.editPage}>
        <Heading type={"h2"}>
          {isEdit ? t("Edit Inventory") : t("Add New Inventory")}
        </Heading>
        <div>
          <FormProvider {...form}>
            <form>
              {Object.keys(inventoryFields).length > 0 && (
                <div className={styles.section}>
                  <Heading type={"h3"}>{t("Inventory Information")}</Heading>
                  <div className={styles.sectionContainer}>
                    {roleView() && (
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"producerId"}
                          rules={{
                            required: {
                              value: true,
                              message: requiredField,
                            },
                          }}
                          defaultValue={inventoryFields.producerId?.value || ""}
                          render={({ field }) => (
                            <FormSelectOptions
                              {...field}
                              required={true}
                              onChange={getProducts}
                              selectOptions={
                                inventoryFields.producerId?.options
                              }
                              placeholder={selectPlaceholder}
                              label={inventoryFields.producerId?.label}
                              errors={errors?.producerId?.message}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"productId"}
                        rules={{
                          required: {
                            value: true,
                            message: requiredField,
                          },
                        }}
                        defaultValue={inventoryFields?.productId?.value || ""}
                        render={({ field }) => (
                          <FormSelectOptions
                            {...field}
                            required={true}
                            selectOptions={inventoryFields.productId?.options}
                            placeholder={selectPlaceholder}
                            label={inventoryFields.productId?.label}
                            errors={errors?.productId?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"bagType"}
                        defaultValue={inventoryFields.bagType?.value || ""}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.bagType.label}
                            type={"text"}
                            placeholder={inputTextPlaceholder}
                            required={false}
                          />
                        )}
                      />
                    </Grid>

                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"numberOfBags"}
                        rules={{
                          required: {
                            value: true,
                            message: requiredField,
                          },
                        }}
                        defaultValue={inventoryFields.numberOfBags?.value || ""}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.numberOfBags.label}
                            type={"number"}
                            placeholder={inputNumberPlaceholder}
                            required={true}
                            errors={errors?.numberOfBags?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"netWeight"}
                        rules={{
                          required: {
                            value: true,
                            message: requiredField,
                          },
                        }}
                        defaultValue={inventoryFields.netWeight?.value || ""}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.netWeight?.label}
                            type={"number"}
                            placeholder={inputNumberPlaceholder}
                            required={true}
                            errors={errors?.netWeight?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"netWeightUnitId"}
                        rules={{
                          required: {
                            value: true,
                            message: requiredField,
                          },
                        }}
                        defaultValue={
                          inventoryFields.netWeightUnitId?.value || "kg"
                        }
                        render={({ field }) => (
                          <RadioInputs
                            {...field}
                            options={inventoryFields.netWeightUnitId?.options}
                            label={inventoryFields.netWeightUnitId?.label}
                            required={true}
                            errors={errors?.netWeightUnitId?.message}
                          />
                        )}
                      />
                    </Grid>

                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"year"}
                        defaultValue={inventoryFields.year?.value || ""}
                        render={({ field }) => (
                          <Date
                            {...field}
                            label={inventoryFields.year?.label}
                            readOnly={inventoryFields.year?.readonly}
                            view={"year"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"altitudeRangeStart"}
                        defaultValue={
                          inventoryFields.altitudeRangeStart?.value || ""
                        }
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.altitudeRangeStart?.label}
                            type={"number"}
                            placeholder={inputNumberPlaceholder}
                            required={false}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"altitudeRangeEnd"}
                        defaultValue={
                          inventoryFields.altitudeRangeEnd?.value || ""
                        }
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.altitudeRangeEnd?.label}
                            type={"text"}
                            placeholder={inputTextPlaceholder}
                            required={false}
                          />
                        )}
                      />
                    </Grid>

                    {roleView() && (
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"ownerOrganizationId"}
                          rules={{
                            required: {
                              value: true,
                              message: requiredField,
                            },
                          }}
                          defaultValue={
                            inventoryFields.ownerOrganizationId?.value || ""
                          }
                          render={({ field }) => (
                            <FormSelectOptions
                              {...field}
                              required={true}
                              selectOptions={
                                inventoryFields.ownerOrganizationId?.options
                              }
                              placeholder={selectPlaceholder}
                              label={inventoryFields.ownerOrganizationId?.label}
                              errors={errors?.ownerOrganizationId?.message}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"icoNumber"}
                        defaultValue={inventoryFields.icoNumber?.value || ""}
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields?.icoNumber?.label}
                            type={"number"}
                            placeholder={inputNumberPlaceholder}
                            required={false}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={4}>
                      <Controller
                        control={control}
                        name={"certification"}
                        defaultValue={
                          inventoryFields.certification?.value || ""
                        }
                        render={({ field }) => (
                          <FormMultipleSelect
                            {...field}
                            required={false}
                            selectOptions={
                              inventoryFields.certification?.options
                            }
                            placeholder={selectPlaceholder}
                            label={inventoryFields.certification?.label}
                            keyName={"certifications"}
                          />
                        )}
                      />
                    </Grid>
                    <Grid columns={8}>
                      <Controller
                        control={control}
                        name={"otherCertifications"}
                        defaultValue={
                          inventoryFields.otherCertifications?.value || ""
                        }
                        render={({ field }) => (
                          <FormInput
                            {...field}
                            label={inventoryFields.otherCertifications?.label}
                            type={"text"}
                            placeholder={inputTextPlaceholder}
                            required={false}
                          />
                        )}
                      />
                    </Grid>
                  </div>
                </div>
              )}
              {cuppingNotes && (
                <>
                  <div className={styles.section}>
                    <Heading type={"h3"}>{t("Cupping Notes")}</Heading>

                    <div className={styles.sectionContainer}>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"aroma"}
                          defaultValue={cuppingNotes.aroma?.value || ""}
                          render={({ field }) => (
                            <FormMultipleSelect
                              {...field}
                              required={false}
                              selectOptions={cuppingNotes.aroma?.options}
                              placeholder={selectPlaceholder}
                              label={cuppingNotes.aroma?.label}
                              keyName={"aroma"}
                            />
                          )}
                        />
                      </Grid>

                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"body"}
                          defaultValue={cuppingNotes.body?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.body?.label}
                              type={"text"}
                              placeholder={inputTextPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"taste"}
                          defaultValue={cuppingNotes.taste?.value || ""}
                          render={({ field }) => (
                            <FormMultipleSelect
                              {...field}
                              required={false}
                              selectOptions={cuppingNotes.taste?.options}
                              placeholder={selectPlaceholder}
                              label={cuppingNotes.taste?.label}
                              keyName={"taste"}
                            />
                          )}
                        />
                      </Grid>

                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"flavour"}
                          defaultValue={cuppingNotes.flavour?.value || ""}
                          render={({ field }) => (
                            <FormMultipleSelect
                              {...field}
                              required={false}
                              selectOptions={cuppingNotes.flavour?.options}
                              placeholder={selectPlaceholder}
                              label={cuppingNotes.flavour?.label}
                              keyName={"flavour"}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={8}>
                        <Controller
                          control={control}
                          name={"notes"}
                          defaultValue={cuppingNotes.notes?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.notes?.label}
                              type={"text"}
                              placeholder={inputTextPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                    </div>
                  </div>
                  <div className={styles.section}>
                    <Heading type={"h4"}>{t("SCA Cupping")}</Heading>

                    <div className={styles.sectionContainer}>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"aromaScore"}
                          defaultValue={cuppingNotes.aromaScore?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.aromaScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"acidityScore"}
                          defaultValue={cuppingNotes.acidityScore?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.acidityScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"bodyScore"}
                          defaultValue={cuppingNotes.bodyScore?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.bodyScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>

                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"flavourScore"}
                          defaultValue={cuppingNotes.flavourScore?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.flavourScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"aftertasteScore"}
                          defaultValue={
                            cuppingNotes.aftertasteScore?.value || ""
                          }
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.aftertasteScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                      <Grid columns={4}>
                        <Controller
                          control={control}
                          name={"balanceScore"}
                          defaultValue={cuppingNotes.balanceScore?.value || ""}
                          render={({ field }) => (
                            <FormInput
                              {...field}
                              label={cuppingNotes.balanceScore?.label}
                              type={"number"}
                              placeholder={inputNumberPlaceholder}
                              required={false}
                            />
                          )}
                        />
                      </Grid>
                    </div>
                  </div>
                </>
              )}
            </form>
          </FormProvider>
        </div>
        <div className={styles.actionsContainer}>
          <div>
            <Button type={"empty"} size={"large"} onClick={cancelHandler}>
              {t("Cancel")}
            </Button>
          </div>
          <div>
            <Button
              type={"fill"}
              size={"large"}
              disabled={!isDirty || disableSubmitButton ? true : false}
              onClick={handleSubmit(submitHandler)}>
              {!isEdit
                ? t("Create")
                : user?.isAdmin || user?.isManager
                ? t("Update")
                : t("Send for Approval")}
            </Button>
          </div>
          {/* <CancelSubmitBtn
          cancelType={"empty"}
          submitType={"fill"}
          size={"large"}
          cancelOnClick={cancelHandler}
          submitOnClick={handleSubmit(submitHandler)}
          cancelText={"Cancel"}
          submitText={user?.isAdmin ? "Update" : "Send for Approval"}
          disabledSubmitButton={!isDirty || disableSubmitButton ? true : false}
        /> */}
        </div>
      </div>
    </LoaderPage>
  );
};

export default memo(InventoryForm);
