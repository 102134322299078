/**
 * InputFieldComponent Label component
 * @props label: the name of the label
 * @props required: if the label will have tee required sign
 * Usage: <InputLabel label={label} required={required} />
 */

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "@atoms/Tooltip/tooltip.component";

import styles from "./input-label.module.scss";

type InputLabelProps = {
  label: string;
  required?: boolean;
};

const InputLabel = ({ label, required }: InputLabelProps) => {
  const { t } = useTranslation();
  const labelRef = useRef<HTMLParagraphElement | null>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (!labelRef.current) {
      return;
    }
    const compareLabel =
      labelRef.current?.scrollWidth > labelRef.current?.clientWidth;
    setIsOverflow(compareLabel);
  }, [labelRef]);

  return (
    <div>
      {label && (
        <div className={styles.labelWrap}>
          <Tooltip disable={!isOverflow} tooltipTitle={t(label)}>
            <p ref={labelRef} className={styles.label}>
              {t(label)}
              {!!required && (
                <span className={styles.requiredIndicator}>*</span>
              )}
            </p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default InputLabel;
