/**
 * Loader Context
 * description: Hide or show global loader
 * Usage: const { isLoading, setIsLoading } = useContext(LoaderContext);
 */

import React, { useState, createContext, useEffect } from "react";
import { UserProps, UserType } from "@context/UserContext/user.types";
import { httpClient } from "@lib/services/httpClient";
import { AxiosError } from "axios";

export const UserContext = createContext<UserProps>({} as UserProps);

const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if (user?.isPendingUser) {
      //window.location.replace(`${window.location.origin}/error/403`);
      //navigate("/");
      //window.location.href = "/error/403";
    }

    // const interceptor = httpClient.interceptors.response.use(
    //   (res) => {
    //     setUser(res.data.user);
    //     console.log(res.data.user, localStorage.getItem("user"));
    //     localStorage.setItem("user", JSON.stringify(res.data.user));
    //     if (res.data.user !== undefined) {
    //     }
    //     return res;
    //   },
    //   (error: AxiosError) => {
    //     return Promise.reject(error);
    //   }
    // );
    // return () => httpClient.interceptors.request.eject(interceptor);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
