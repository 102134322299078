import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import PageGrid from "@templates/PageGrid/page-grid.template";
import Button from "@atoms/Button/button.component";

import styles from "./not-found.module.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageGrid>
      <div className={styles.container}>
        <div className={styles.infoWrap}>
          <div className={styles.title}>404</div>
          <div className={styles.subTitle}>{t("The page you are looking for doesn't exist.")}</div>
          <div className={styles.btnWrap}>
            <Button onClick={() =>  navigate("/")}>{t("Back to home")}</Button>
          </div>
        </div>
        <div className={styles.imgWrap}>
          <img
            src={require('@assets/images/no-items-trasperant.png')}
            width='100%'
            alt='no image'
          />
        </div>
      </div>
    </PageGrid>
  )
}

export default NotFound;