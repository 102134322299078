import React from "react";

const handleErrorsHelper = (data: any, setErrors: any, setAlert: any) => {
  switch (data.status) {
    case 500:
      return (
        setErrors(),
        setAlert({
          state: "error",
          message: `Server error! Please contact our support team.`,
        }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      );
      break;

    case 422:
      return (
        setErrors(data.errors),
        setAlert({
          state: "error",
          message: data.title,
        }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      )
      break;

    default:
      return (
        setErrors({}),
        setAlert({ state: "error", message: data.title }),
        setTimeout(() => {
          setAlert({});
        }, 4000)
      );
  }
};

export default handleErrorsHelper;
