import {forwardRef, SyntheticEvent, useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import { Autocomplete, createTheme, Paper, ThemeProvider } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import InputLabel from "@atoms/Fields/Input/InputLabel/input-label.components";
import { InventoryFormFields } from "@pages/InventoryForm/inventory-form.type";

import styles from "./form-multi-select.module.scss";
import "./form-multi-select.styles.scss";

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "$fs-normal",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #c4c4c4",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #c4c4c4",
          },
          ".MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
            fontSize: "1em",
            fill: "#000",
          }
        },
      },
    },
  },
});

type MultiSelectOption = { key: number; value: string };
type Props = {
  selectOptions: MultiSelectOption[],
  placeholder: string,
  label: string,
  fields?: InventoryFormFields,
  canClearValue?: boolean,
  required?: boolean,
  keyName: string,
};

const FormMultipleSelect = forwardRef(({ selectOptions, placeholder, required, keyName, fields, label, canClearValue = true, ...rest }: Props, ref) => {
  const { setValue } = useFormContext();
  const [selected, setSelected] = useState<MultiSelectOption[]>([]);
  const formProps: any = rest;

  const mapSelections = (values: MultiSelectOption[]) => {
    Array.isArray(values) ? values.map((val) => val?.key) : [];
  }

  useEffect(() => {
    if (formProps.value) {
      const preselect = (formProps.value as number[]).map<MultiSelectOption>(
        (id: number) =>
          selectOptions.find((option: MultiSelectOption) => option.key === id)!
      );
      setSelected(preselect);
      keyName = mapSelections(preselect) as any;
    } else {
      setSelected([]);
    }
  }, []);

  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    value: MultiSelectOption[]
  ) => {
    let arr: any = [];
    value.map((r) => {
      arr.push(r.key)
    })

    setValue(formProps.name, arr, { shouldDirty: true })
    setSelected(value);
    keyName = mapSelections(value) as any;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className='multi-select-wrap'>
        <FormControl sx={{ width: "100%", height: "100%" }}>
          <InputLabel
            label={label ? label : ""}
            required={!!required}
          />
          <Autocomplete
            className={styles.test}
            disableClearable={!canClearValue}
            isOptionEqualToValue={(option, value) => option.key == value.key}
            disableCloseOnSelect
            multiple
            id='multiselect-chips'
            options={selectOptions}
            getOptionLabel={(option) => option.value}
            value={selected}
            noOptionsText={"No Options"}
            onChange={handleChange}
            PaperComponent={(props) => <Paper elevation={8} {...props} />}
            ChipProps={{
              classes: {
                label: styles.input,
              },
            }}
            classes={{
              option: styles.input,
              endAdornment: styles.endAdornment,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={!selected.length ? "Select" : ""}
              />
            )}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  padding: "$fs-normal",
                }}>
                {selected && (
                  <div style={{ paddingRight: "0.8rem" }}>
                    <DoneIcon />
                  </div>
                )}
                {option.value}
              </li>
            )}
            clearIcon={<div className={styles.clearButton}>CLEAR</div>}
          />
        </FormControl>
      </div>
    </ThemeProvider>
  );
});

FormMultipleSelect.displayName = "FormMultipleSelect";

export default FormMultipleSelect;
