import React, { memo } from "react";
import Button from "@atoms/Button/button.component";
import Portal from "@molecules/Portal/portal.component";
import DialogComponent from "@organisms/Dialog/dialog.component";
import { useTranslation } from "react-i18next";

type ApproveDocumentDialogProps = {
  opened: boolean;
  documentName: string;
  producer: string;
  onClose: (value: any) => void;
  onSubmit: () => void;
};

const Component = ({
  opened,
  onClose,
  onSubmit,
  documentName,
  producer,
}: ApproveDocumentDialogProps) => {
  const { t } = useTranslation();
  const dialog = {
    title: t("ApproveDocumentTitle", {
      documentName,
      producer,
    }),
    actions: (
      <Button type={"fill"} size={"small"} onClick={onSubmit} disabled={false}>
        {t("Approve")}
      </Button>
    ),
    content: <div>{t("ApproveDocumentBody", { producer })}</div>,
  };
  return (
    <Portal overlay={"overlay"}>
      {opened && (
        <DialogComponent {...dialog} closeDialog={onClose} open={opened} />
      )}
    </Portal>
  );
};

export const ApproveDocumentDialog = memo(Component);
