/**
 * Loader Context
 * description: Hide or show global loader
 * Usage: const { isLoading, setIsLoading } = useContext(LoaderContext);
 */

import React, { useState, createContext } from 'react';

export type LoaderProps = {
  setIsLoading: any;
  isLoading: boolean;
};

export const LoaderContext = createContext<LoaderProps>({isLoading: false, setIsLoading: false});

const LoaderContextProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ setIsLoading, isLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContextProvider;
