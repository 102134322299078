/**
 * Hamburger Menu component
 * Description: Used inside the Header for the small resolutions
 * Usage:  <HamburgerMenu logout={logout} userMessage={userMessage} />
 * @props: logout
 * @props: userMessage
 */

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  EXECUTE_TRADES_URL,
  ALMACENA_DOMAIN,
  ORIGIN_URL,
} from "@lib/config/config";
import CloseIcon from "@assets/icons/Close";
import UserIcon from "@assets/icons/User";
import { inventoryDashboard } from "@base/globalVariables/page-links";

import styles from "./hamburger-menu.module.scss";
import { HamburgerMenuProps } from "./hamburger-menu.types";

function HamburgerMenu({ logout, userMessage, user }: HamburgerMenuProps) {
  const [menuOpened, setMenuOpened] = useState(false);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuOpened && wrapperRef.current) {
        setMenuOpened(false);
      }
    };

    if (menuOpened) document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuOpened]);

  return (
    <div className={styles.container}>
      {menuOpened ? (
        <div className={styles.fullPage} ref={wrapperRef}>
          <div
            className={styles.hamburgerMenu}
            onMouseDown={(e) => e.stopPropagation()}>
            <div className={styles.topLine}>
              <p>Menu</p>
              <div
                className={styles.closeButton}
                onClick={() => {
                  setMenuOpened(false);
                }}>
                <CloseIcon height={20} width={20} />
              </div>
            </div>
            <div className={styles.userName}>
              <UserIcon />
              <h3>{userMessage}</h3>
            </div>

            <div className={styles.navigationList}>
              {user.isPendingUser === false && (
                <>
                  <a href={ORIGIN_URL} className={styles.navigationEl}>
                    {t("Almacena Origin Data")}
                  </a>
                  <a href={EXECUTE_TRADES_URL} className={styles.navigationEl}>
                    {t("Trade Dashboard")}
                  </a>
                  {!user.isUser && (
                    <Link
                      to={inventoryDashboard}
                      className={styles.navigationEl}>
                      {t("Inventory Dashboard")}
                    </Link>
                  )}
                  <a
                    href={`${ALMACENA_DOMAIN}/book-platform-demo/`}
                    className={styles.navigationEl}>
                    {t("Book Platform Demo")}
                  </a>
                </>
              )}
              <a className={styles.navigationEl} onClick={logout}>
                Sign out
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.menuBars}
          onClick={() => {
            setMenuOpened(true);
          }}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      )}
    </div>
  );
}

export default HamburgerMenu;
