/**
 * Radio Inputs component
 * @props inputs: array of object with all the radio inputs
 * @props onChange: on radio change send the value to the parent
 * @props label: if the radio group has a label
 * @props required: if the radio group is required
 * Usage: <RadioInputs inputs={radioButtons} onChange={(value: any) => console.log('radio value changed', value)} label={'Net Weight Units'} required={true} />
 * TODO: make it required
 */

/**
 * Radio Inputs component
 * @props inputs: array of object with all the radio inputs
 * @props onChange: on radio change send the value to the parent
 * @props label: if the radio group has a label
 * @props required: if the radio group is required
 * Usage: <RadioInputs inputs={radioButtons} onChange={(value: any) => console.log('radio value changed', value)} label={'Net Weight Units'} required={true} />
 */

import React, {forwardRef} from "react";

import FieldError from "@atoms/Fields/FieldError/field-error.component";
import InputLabel from "@atoms/Fields/Input/InputLabel/input-label.components";

import {RadioInputsProps} from "./radio-inputs.types";
import styles from "./radio-inputs.module.scss";

const RadioInputs = forwardRef((
  {
    required,
    options,
    label,
    errors,
    ...rest
  }: RadioInputsProps, ref) => {
  const formOptions: any = rest;

  return (
    <div className={styles.container}>
      <InputLabel label={label} required={required} />
      {options &&
        <>
          <div className={`
            ${styles.radioContainer}
            ${!!errors ? styles.error : ""}
          `}>
            {options.map((radio, i) => {
              return (
                <label key={i} className={styles.radioWrap}>
                  <input
                    {...formOptions}
                    type='radio'
                    name={radio.name}
                    value={radio.value}
                    checked={radio.value === formOptions.value || radio.key === formOptions.value}
                  />
                  {radio.value}
                </label>
              );
            })}
          </div>
          <div>
            {errors &&
              <FieldError error={errors} />
            }
          </div>
        </>
      }
    </div>
  );
});

RadioInputs.displayName = "RadioInputs";

export default RadioInputs;
