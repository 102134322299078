import { useCallback, Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

import TableViewHead from "../TableViewHead/table-view-head.component";
import { useDashboardContext } from "@hooks/useDashboard.context";

import { formatVolume } from "@lib/helpers/FormatNumber/helpers";
import LoadingSpinner from "@molecules/LoadingSpinner/loading-spinner.component";
import ActionsColumn from "../Columns/Actions/actions-column.component";
import StatusColumn from "../Columns/Status/status-column.component";
import GeneralColumn from "../Columns/GeneralColumn/general-column.component";
import InventoryColumn from "../Columns/Inventory/inventory-column.component";
import DefaultTableCell from "../Columns/DefaultTableCell/default-table-cell.component";

import styles from "./table-view.module.scss";
import HorizontalScrolling from "../HorizontalScrolling/horizontal-scrolling.component";
import NoItems from "@molecules/NoItems/NoItems.component";

const TableView = ({ inventories, loading }) => {
  const { t } = useTranslation();
  // const { isAdmin } = useUserRole();
  const { columns, setFilterByColumns } = useDashboardContext();
  const navigate = useNavigate();
  const ref = useRef(null);
  const refTable = useRef(null);

  const columnSortHandler = useCallback(
    (column, value) => {
      setFilterByColumns(column, value);
    },
    [setFilterByColumns]
  );

  const editHandler = useCallback(
    (inventory) => {
      navigate(`/manage-deal/${inventory?.id}`, {});
    },
    [navigate]
  );

  const renderColumnHead = useCallback(
    (columnName) => {
      switch (columnName) {
        case "inventory":
          return (
            <TableViewHead
              columnName={"Inventory"}
              sorting={true}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "quantity":
          return (
            <TableViewHead
              columnName={"Quantity"}
              sorting={true}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "location":
          return (
            <TableViewHead
              columnName={"Location"}
              sorting={true}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "status":
          return (
            <TableViewHead
              columnName={"Status"}
              sorting={true}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "producer":
          return (
            <TableViewHead
              columnName={"Producer"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "numberOfBags":
          return (
            <TableViewHead
              columnName={"Number of Bags"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "bagType":
          return (
            <TableViewHead
              columnName={"Bags Size"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "netWeightUnit":
          return (
            <TableViewHead
              columnName={"Net Weight Units"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "icoNumber":
          return (
            <TableViewHead
              columnName={"Invoice No"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "originCountry":
          return (
            <TableViewHead
              columnName={"Origin Country"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "owner":
          return (
            <TableViewHead
              columnName={"Owner"}
              sorting={true}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "location":
          return (
            <TableViewHead
              columnName={"Location"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "region":
          return (
            <TableViewHead
              columnName={"Regions"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "altitudeRangeStart":
          return (
            <TableViewHead
              columnName={"Altitude Range Start"}
              sorting={false}
              onSorting={columnSortHandler.bind(this, columnName)}
            />
          );
        case "altitudeRangeEnd":
          return (
            <TableViewHead columnName={"Altitude Range End"} sorting={false} />
          );
        case "cropStartDate":
          return (
            <TableViewHead columnName={"Crop Start Date"} sorting={false} />
          );
        case "cropEndDate":
          return <TableViewHead columnName={"Crop End Date"} sorting={false} />;
        case "commodityType":
          return (
            <TableViewHead columnName={"Commodity Type"} sorting={false} />
          );
        case "certification":
          return (
            <TableViewHead columnName={"Certifications"} sorting={false} />
          );
        case "otherCertifications":
          return (
            <TableViewHead
              columnName={"Other Certifications"}
              sorting={false}
            />
          );

        case "variety":
          return <TableViewHead columnName={"Variety"} sorting={false} />;
        case "process":
          return <TableViewHead columnName={"Process"} sorting={false} />;
        case "flavour":
          return <TableViewHead columnName={"Flavour"} sorting={false} />;
        case "grade":
          return <TableViewHead columnName={"Grade"} sorting={false} />;
        case "taste":
          return <TableViewHead columnName={"Taste"} sorting={false} />;
        case "aroma":
          return <TableViewHead columnName={"Aroma"} sorting={false} />;
      }
    },
    [columnSortHandler]
  );

  const renderColumnActions = useCallback(
    (inventory) => {
      return (
        <div className={styles.actionsColumn} key={inventory.id}>
          <IconButton
            onClick={editHandler.bind(this, inventory.id)}
            aria-label="delete"
            size="medium"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          {/* {isAdmin ? (
            <DeleteButton
              buttonTitle={t("Delete")}
              confirmationTitle={t(
                "Are you sure you want to delete this deal?"
              )}
              agreementHandler={deleteDeal.bind(this, deal.Deal)}
            />
          ) : null} */}
        </div>
      );
    },
    [editHandler, t]
  );

  const assignColumnValue = (column, inventory) => {
    switch (column.toLowerCase()) {
      // case "source":
      //   return "<SourceColumn traceabilityId={inventory.actions.traceability} />";
      //   break;
      case "inventory":
        return (
          <InventoryColumn
            netWeight={inventory.netWeight}
            netWeightUnit={inventory.netWeightUnit?.value}
            name={inventory.name}
            number={inventory.number}
            producer={inventory.producer}
            icoNumber={inventory.icoNumber}
            inventoryId={inventory.id}
          />
        );
      case "owner":
        return <GeneralColumn value={inventory.owner} title={"owner"} />;
      case "quantity":
        return (
          <GeneralColumn
            value={`${
              inventory.netWeight
                ? formatVolume(
                    inventory.netWeight,
                    inventory.netWeightUnit?.value
                  )
                : ""
            }`}
            title={"quantity"}
          />
        );
      case "location":
        return (
          <GeneralColumn
            value={inventory.originCountry?.value}
            title={"location"}
          />
        );
      case "status":
        return <StatusColumn status={inventory.status} />;
      default: {
        const columnValue =
          inventory[column] &&
          (typeof inventory[column] === "string" ||
            typeof inventory[column] === "number")
            ? inventory[column]
            : null;
        return (
          <DefaultTableCell
            value={columnValue ? columnValue : inventory[column]?.value || "--"}
          />
        );
      }
    }
  };

  return (
    <div className={styles.tableWrap}>
      <HorizontalScrolling
        refParent={refTable}
        refComponent={ref}
        items={inventories}
        loading={loading}
      />
      <TableContainer
        component={Paper}
        sx={{ minHeight: loading ? "100vh" : "" }}
        className={styles.tableContainer}
        ref={ref}
      >
        <Table size="medium" className={styles.table} ref={refTable}>
          <TableHead className={styles.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <Fragment key={column}>{renderColumnHead(column)}</Fragment>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {!loading &&
              inventories.map((inventory) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={inventory.id}
                >
                  {columns.map((column, i) => (
                    <TableCell scope="row" key={i}>
                      {assignColumnValue(column, inventory)}
                    </TableCell>
                  ))}
                  <TableCell>
                    <ActionsColumn actions={inventory.actions} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {loading && (
          <div className={styles.loaderWrap}>
            <LoadingSpinner />{" "}
          </div>
        )}
        {!loading && !inventories.length && (
          <NoItems
            message={t(
              "There are no inventories created yet. Please create a new deal."
            )}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default TableView;
