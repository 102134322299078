/**
 * Variables used in JS
 */

export const isSearchVisible: number = 10;
export const tabletMediaQuery: number = 1024;
/* ========= Statuses ========= */
export const statusApproved: string = "Approved";
export const statusRejected: string = "Rejected";
export const statusUploaded: string = "Uploaded";
export const statusPending: string = "Pending";
export const statusPendingApproval: string = "Pending Approval";
export const statusSoldOut: string = "Sold Out";
export const statusArchived: string = "Archived";