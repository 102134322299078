/**
 * Rich Text that submits
 * description: with submit button that sends to an endpoint
 * @props handleSubmit : where to send to.
 * @props counter: needed to refresh a specific component when submitted
 */

import React, { useState, memo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import messagesService from "@lib/services/MessagesService/messages-service";
import Heading from "@atoms/Headings/headings.component";
import Button from "@atoms/Button/button.component";
import { AlertTypes } from "@molecules/Alert/alert.types";
import Alert from "@molecules/Alert/alert.component";
import RichTextComponent from "@organisms/RichText/rich-text.component";

import { RichTextSubmitProps } from "./rich-text-submit.types";
import styles from "./rich-text-submit.module.scss";

const RichTextSubmit = ({
  handleSubmit,
  counter,
  isMessageSendable,
  setIsMessageSendable,
  isSendButtonEnabled,
}: RichTextSubmitProps) => {
  const { id: previewId } = useParams();
  const { t } = useTranslation();

  const [newMessage, setNewMessage] = useState("<p><br></p>");
  const [error, setError] = useState<{ [key: string]: any } | null>({
    error: null,
  });
  const [alert, setAlert] = useState<AlertTypes>({} as AlertTypes);

  const richTextDefaultValue = "<p><br></p>";
  const handleButtonStatus = newMessage !== richTextDefaultValue;

  const postMessage = useCallback(() => {
    const regex = /(<([^>]+)>)/gi;
    const message: string = newMessage ? newMessage.replace(regex, "") : "";
    if (message.length > 1000) {
      setError({
        message: t("Maximum message length is {{count}} characters.", {
          count: 1000,
        }),
      });
      return;
    } else {
      setError(null);
    }
    const param = {
      inventoryId: previewId,
      message: newMessage,
    };

    messagesService
      .postMessage(param)
      .then(() => {
        handleSubmit(counter + 1);
        setNewMessage(richTextDefaultValue); // clear the message text
        setAlert({
          state: "success",
          message: `Your message has been sent.`,
        });
        setTimeout(() => {
          setAlert({ message: "" });
        }, 4000);
      })
      .catch();
  }, [newMessage]);

  useEffect(() => {
    isSendButtonEnabled(!handleButtonStatus);
  }, [isSendButtonEnabled, handleButtonStatus]);

  useEffect(() => {
    if (isMessageSendable && handleButtonStatus) {
      setTimeout(() => {
        postMessage();
        setIsMessageSendable(false);
        isSendButtonEnabled(false);
      });
    }
  }, [isMessageSendable, setIsMessageSendable]);

  return (
    <div className={styles.richTextContainer}>
      <Alert alert={alert} />
      <Heading type={"h4"}>
        {t("Send a Message with attached Documents")}
      </Heading>
      <RichTextComponent
        error={error}
        setNewMessage={setNewMessage}
        currentMessage={newMessage}
      />
    </div>
  );
};

export default memo(RichTextSubmit);
