/**
 * No Items Component
 * description: has a image and text which describe that no items
 * Usage: <NoItems message={'There are no deals created yet'} />
 * @props message: the placeholder of the textarea.
 */

import styles from "./NoItems.module.scss";

function NoItems({ message = "No items" }) {
  return (
    <div className={styles.container}>
      <img
        src={require("../../../Assets/images/no-items-trasperant.png")}
        height='100%'
        alt='no image'
      />
      <div className={styles.message}>{message}</div>
    </div>
  );
}

export default NoItems;
