import { KeyboardEvent, useCallback, useEffect, useRef } from "react";

type PageButtonInteractionProps = {
  children: any;
  setKeyPressed: any;
};

const PageButtonInteraction = ({
  children,
  setKeyPressed,
}: PageButtonInteractionProps) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    elementRef.current.focus();
  }, []);

  const handleKeyChange = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setKeyPressed(event.key);
    },
    []
  );

  return (
    <div
      onKeyDown={handleKeyChange}
      tabIndex={0}
      ref={elementRef}
      id={"page-interaction"}>
      <div>{children}</div>
    </div>
  );
};

export default PageButtonInteraction;
