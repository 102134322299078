import { useContext } from "react";
import { Link } from "react-router-dom";

import { WindowContext } from "@context/window-resize.context";
import { tabletMediaQuery } from "@base/globalVariables/global.variables";
import MenuDropdownComponent from "@molecules/MenuDropdown/menu-dropdown.component";

import { ActionsColumnProps } from "./actions-column.types";
import styles from "./actions-column.module.scss";

const ActionsColumn = ({
  actions,
  orientation = "horizontal",
}: ActionsColumnProps) => {
  const { clientWidth } = useContext(WindowContext);

  return (
    <div className={`${styles.actionMenu} ${styles[orientation]}`}>
      <MenuDropdownComponent type={"ellipsis"}>
        {Object.values(actions).map((action) => (
          <div className={styles.actionItem} key={action.name}>
            <Link to={action.link}>{action.name}</Link>
          </div>
        ))}
      </MenuDropdownComponent>
    </div>
  );
};

export default ActionsColumn;
