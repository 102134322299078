/**
 * Dynamic Grid component
 * description: The BE will tell us the inputs in how many columns they will be in.
 * @props: columns
 * @props: children
 * Usage: <Grid columns={item.GridNumber} key={index}></Grid>
 */

import {GridComponentTypes} from "@atoms/Grid/StaticGrid/static-grid.types";

import './dynamic-grid.styles.scss';

const DynamicGrid = ({columns, children, offset, offsetPosition}: GridComponentTypes) => {
  return (
    <div className={`dynamic-grid-section grid-columns--${columns} add-margin offset-${offsetPosition}-${offset}`}>
      {children}
    </div>
  )
}

export default DynamicGrid;
