/**
 * Horizontal Smooth Scrolling
 * @description: scroll smoothly
 * @param element: which element to scroll
 * @param direction: where to scroll it
 * @param speed: how fast to scroll it
 * @param distance: how much to scroll it
 * @param step
 */

type SideScrollParams = {
  element: any;
  direction: "right" | "left";
  speed: number;
  distance: any;
  step: number;
};
export function sideScroll({
  element,
  direction,
  speed,
  distance,
  step,
}: SideScrollParams) {
  let scrollAmount = 0;

  const slideTimer = setInterval(() => {
    if (direction === "left") {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);

  return element.scrollLeft;
}
