/**
 * Field Error component
 * description: the error of a field
 * @props: error
 * Usage: <FieldError error={localError[0]} />
 */

import {useTranslation} from "react-i18next";

import styles from "./field-error.module.scss";
import { FieldErrorProps } from "./field-error.types";

const FieldError = ({ error }: FieldErrorProps) => {
  const { t } = useTranslation();

  return (
    <>
      {error &&
        <p className={styles.error}>{t(error)}</p>
      }
    </>

  )
}

export default FieldError;
