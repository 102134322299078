/**
 * General Column component
 * description: Can be used for all the columns that has just text
 * @pros value
 * @props title
 */

import { useContext } from "react";

import { WindowContext } from "@context/window-resize.context";
import {tabletMediaQuery} from "@base/globalVariables/global.variables";

import { GeneralColumnProps } from "./general-column.types";

const GeneralColumn = ({value, title}: GeneralColumnProps) => {
  const { clientWidth } = useContext(WindowContext);
  //configure class based on the title. Needed for the width of the columns
  const confClass = title.split(" ").join('-');

  return (
    <div className={`column-wrap ${confClass}`}>
      {clientWidth <= tabletMediaQuery && (
        <span className={"column-title"}>{title}:</span>
      )}
      <span className={"column-info"}>
        {value}
      </span>
    </div>
  )
}

export default GeneralColumn;
