import { API_ROOT } from "../config/config";

import {
  InventoryFilterParams,
  InventoryFilterResponse,
} from "./inventory-service.type";
import { httpClient } from "./httpClient";

const inventoryService = {
  getInventoryPreview: function (previewId: string) {
    return httpClient.get(
      `${API_ROOT}/api-inventory/inventory-preview/${previewId}`,
      {}
    );
  },

  archiveInventory: function (inventoryId: string | number) {
    return httpClient.get(
      `${API_ROOT}/api-inventory/inventory-archive/${inventoryId}`
    );
  },

  getInventoryList: function (params: InventoryFilterParams) {
    const defaultPage = 1;
    const defaultPageSize = 10;

    const payload = {
      ...params,
      page: params?.page ? params.page : defaultPage,
      pageSize: params?.pageSize ? params.pageSize : defaultPageSize,
    };

    return httpClient.post(
      `${API_ROOT}/api-inventory/inventory-filter`,
      payload
    );
  },

  getInventoryFields: function ({
    action,
    params,
  }: {
    action: "update_inventory" | "create_inventory";
    params: { [key: string]: any };
  }) {
    return httpClient.get(`${API_ROOT}/api-inventory/fields/${action}`, {
      params,
    });
  },

  createInventory: function (params: FormData) {
    return httpClient.post(`${API_ROOT}/api-inventory/lots`, params, {});
  },

  updateInventory: function (params: FormData, inventoryId: string) {
    return httpClient.post(
      `${API_ROOT}/api-inventory/lots/${inventoryId}`,
      params,
      {}
    );
  },

  updateInventoryStatus: function (
    lotId: number,
    rejectReason: string,
    buttonType: string
  ) {
    const payload = {
      buttonType: buttonType,
      rejectReason: rejectReason,
    };

    return httpClient.post(
      `${API_ROOT}/api-inventory/update-status/${lotId}`,
      payload,
      {}
    );
  },
};

export default inventoryService;
