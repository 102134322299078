/**
 *Header Search component
 * @description: Search inside the header component
 * @param
 */

import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm, FormProvider } from "react-hook-form";

import SearchIcon from "@assets/icons/Search";
import MuiOutlinedInput from "@atoms/Fields/MuiOutlinedInput/mui-outlined-input.component";

import styles from "./header-search.module.scss";
import "./header-search.styles.scss";

const HeaderSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const submitRef = useRef<HTMLDivElement | null>(null);

  const form = useForm();
  const { getValues } = form;

  const search = () => {
    navigate("/", {
      state: {
        filter: getValues("SearchInput"),
      },
    });
  };

  return (
    <section className={`${styles.searchContainer} header__search-container`}>
      <div className={styles.searchInput}>
        <FormProvider {...form}>
          <Controller
            name={"SearchInput"}
            defaultValue={(state as any)?.filter || ""}
            render={({ field }) => (
              <MuiOutlinedInput
                {...field}
                type={"text"}
                placeholder={t("Search")}
                onChange={(event) => {
                  if (event.target.value === "") {
                    navigate("/", { state: null });
                  }
                  field.onChange(event);
                }}
                onKeyDownCapture={(event) => {
                  if (event.key === "Enter" && submitRef.current) {
                    submitRef.current.click();
                  }
                }}
              />
            )}
          />
        </FormProvider>
      </div>
      <div className={styles.searchIcon} onClick={search} ref={submitRef}>
        <SearchIcon />
      </div>
    </section>
  );
};

export default HeaderSearch;
