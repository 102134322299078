/**
 * Confirmation popup component
 * description: returns the user back to the previous page he has been
 * @props message: title of the screen
 * @props cancellationHandler: send to the parent that cancel was clicked
 * @props agreementHandler: send to the parent that agree was clicked
 * !NOT BEING USED FOR NOW.
 */

import React from "react";

import Portal from "@molecules/Portal/portal.component";
import DialogComponent from "@organisms/Dialog/dialog.component";
import CancelSubmitBtn from "@molecules/Buttons/CancelSubmitBtn/cancel-submit-btn.component";

import { ConfirmationPopupProps } from "./confirmation-popup.types";
import styles from "./confirmation-popup.module.scss";
import { useTranslation } from "react-i18next";

function ConfirmationPopup({
  title,
  cancellationHandler,
  agreementHandler,
  cancelText = "Cancel",
  agreeText = "Decline",
  isOpen,
  setIsOpen,
  children,
  disableConfirmButton,
}: ConfirmationPopupProps) {
  const { t } = useTranslation();

  const dialog = {
    title: title,
    actions: (
      <CancelSubmitBtn
        cancelType={"fill"}
        submitType={"fill"}
        size={"small"}
        cancelOnClick={() => cancellationHandler()}
        submitOnClick={() => agreementHandler()}
        cancelText={t(cancelText)}
        submitText={t(agreeText)}
        disabledSubmitButton={disableConfirmButton}
      />
    ),
    content: children,
  };

  return (
    <Portal overlay={"overlay"}>
      <DialogComponent {...dialog} closeDialog={setIsOpen} open={isOpen} />
    </Portal>
  );
}

export default ConfirmationPopup;
