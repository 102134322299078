/**
 * Document Attachment Component
 * description: Show an information about the attached document
 * @props file: object of key and value
 * Usage: <DocumentAttachment file={testFile} />
 */

import sizeConverter from "@lib/helpers/SizeConverter/size-converter.helper";
import AttachmentIcon from "@assets/icons/Attachment";
import DeleteButton from "@atoms/Button/DeleteButton/delete-button.component";

import { DocumentAttachmentProps } from "./document-attachment.types";
import styles from "./document-attachment.module.scss";

const DocumentAttachment = ({ fileSize, fileName, onClick}: DocumentAttachmentProps) => {
  return (
    <div className={styles.document}>
      <div>
        <div className={styles.documentTitle}>
          <AttachmentIcon />
          <p className={styles.documentName} title={fileName || fileName}>
            {fileName}
          </p>
          <DeleteButton onClick={() => onClick(fileName)} />
        </div>

        <div className={styles.uploadInformation}>
          <div className={styles.documentName}>
            {fileName}
          </div>
          <div className={styles.infoSizeDateWrap}>
            {fileSize && fileSize > 0
              ? <span className={styles.infoSize}>{sizeConverter(fileSize, 2)}</span>
              : <span></span>
            }
            {/*<span>*/}
            {/*  <TimeDifference timestamp={file.lastModified || file.date} />*/}
            {/*</span>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentAttachment;
