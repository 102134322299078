/**
 * Textarea component
 * description: use together with React hook form
 * @param rows: the height of the textarea
 * @param counter: to show the letter counter or not
 * @param error: if we have a form error and the textarea is required - show red border around the textarea
 */

import {useTranslation} from "react-i18next";

import InputLabel from "@atoms/Fields/Input/InputLabel/input-label.components";

import { FormTextareaProps } from "./form-textarea.types";
import styles from "./form-textarea.module.scss";

const FormTextarea = ({rows = 5, placeholder, disabled, label, required, counter, error}: FormTextareaProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <InputLabel label={label ? label : ''} required={required} />
      <textarea
        className={styles.textarea}
        // style={{ borderColor: showInfo ? 'red' : '' }}
        rows={rows}
        disabled={disabled}
        placeholder={t(placeholder)}
      />
      {/* <div
        className={styles.info}
        style={{ color: showInfo ? 'red' : '' }}>
        {showInfo &&
            <p className={styles.message}>{'The maximum length has been reached.'}</p>}
        <p className={styles.counter}>{`${count}/${item.Length}`}</p>
      </div> */}
    </div>
  )
}

export default FormTextarea;