/**
 * Page Grid Template
 * description: used in order to avoid repeating code.
 * @props: children
 * Usage: <PageGrid>{children}</PageGrid>
 */

import styles from "./page-grid.module.scss";

const PageGrid = ({ children }: any) => {
  return <div className={styles.mainContainer}>{children}</div>;
};

export default PageGrid;
