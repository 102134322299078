/**
 * InputFieldComponent Label component
 * @props label: the name of the label
 * @props required: if the label will have tee required sign
 * Usage: <InputLabel label={label} required={required} />
 */

import {useTranslation} from "react-i18next";

import styles from "./input-label.module.scss";
import { InputLabelProps } from "./input-label.types";

const InputLabel = ({ label, required }: InputLabelProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {label && (
        <div className={styles.labelWrap}>
          <label className={styles.label}>{t(label)}</label>
          {!!required && <span className={styles.requiredIndicator}>*</span>}
        </div>
      )}
    </div>
  );
};

export default InputLabel;
