import React from "react";
import parse from "html-react-parser";

import Heading from "@atoms/Headings/headings.component";

import { MessageContentProps } from "./message-content.types";
import styles from "./message-content.module.scss";
import { useTranslation } from "react-i18next";

const MessageContent = ({ message }: MessageContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading type={"h5"}>Message:</Heading>
      {message.message &&
        message.message.split("\n").map((item: string, index: number) => {
          if (item === "\r") {
            return <br key={index} />;
          }
          return (
            <div className={styles.message} key={index}>
              {parse(String(item))}
            </div>
          );
        })}
    </>
  );
};

export default MessageContent;
