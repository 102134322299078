import styled from "@emotion/styled";
import { Button as MaterialButton } from "@mui/material";

const StyledButton = styled(MaterialButton)`
  border: 1px solid var(--primary-btn-border-light); 
  border-radius: 2px;
  font-size: $fs-normal;
  width: 100%;
  transition: all .2s ease-in-out;
  
  &.icon-button {
    font-weight: 400;
    font-size: $fs-normal;
    text-transform: capitalize;    
  }

  .btn-icon {
    margin-right: 10px;
  }

  &.button-type--text {
    border: none;
    text-decoration: underline;
    color: var(--primary-btn-bg-light-blue);

    &:hover {
      background: none;
      text-decoration: none;
    }
  }

  &.button-type--fill {
    border: 1px solid var(--primary-btn-bg-light-blue);
    background-color: var(--primary-btn-bg-light-blue);
    color: var(--primary-btn-text-light);

    &:hover {
      background-color: var(--primary-btn-bg-dark-blue);
      color: var(--primary-btn-text-light);
    },
  }

  &.button-type--none {
    border: none;
    padding: 0;
    line-height: 1;
    width: auto;
    text-transform: none;

    &:hover {
      background: none;
    }
  }

  &.button-type--link {
    border: none;
    background: none;
    font-weight: 400;
    color: var(--primary-btn-bg-light-blue);
    cursor: pointer;

    &:hover {
    }
}

  &:disabled {
    background-color: var(--primary-btn-bg-disabled);
  }

  &.button-type--empty {
    border: 1px solid var(--primary-btn-bg-light-blue);
    background-color: var(--primary-btn-bg-light-blue);
    color: var(--primary-btn-text-light); 
    
    &:hover {      
      background-color: var(--primary-btn-bg-dark-blue);
      color: var(--primary-btn-text-light);
      
      .btn-icon {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(144deg) brightness(104%) contrast(101%);
      }
    }
    
    a {
      color: var(--primary-btn-text-light);
    }  
  }
  
  &.button-type--decline {
    border: 1px solid var(--primary-btn-bg-apricot);
    background-color: var(--primary-btn-bg-apricot);
    color: var(--primary-btn-text-light); 
    
    &:hover {      
      background-color: var(--primary-btn-bg-orange);
      color: var(--primary-btn-text-light);
    }
    
    a {
      color: var(--primary-btn-text-light);
    }  
  }
  
  &.button-type--declined,
  &.button-type--rejected {
    --currentColor: var(--primary-btn-bg-error);
    background: var(--primary-btn-bg-error);
    color: #fff;
    border: 1px solid var(--primary-btn-bg-error);

    &:hover {
      background: none;
      text-decoration: none;
      color: var(--primary-btn-bg-error);
    }
  }

  &.button-type--approved {
    --currentColor: #4241DD;

    border: 1px solid #4241DD;
    background-color: #469e42;
    color: #fff;

    &:hover {
      background-color: #51A54C;
      border: 1px solid #51A54C;
    }
  }

  &.button-type--sold-out {
    --currentColor: #F53C1E;

    border: 1px solid #F53C1E;
    background-color: #f44f4f;
    color: #fff;

    &:hover {
      background-color: #51A54C;
      border: 1px solid #51A54C;
    }
  }

  &.button-type--new {
    --currentColor: #426392;

    border: 1px solid #426392;
    background-color: #426392;
    color: #fff;

    &:hover {
      background-color: #1C3C6A;
      border: 1px solid #1C3C6A;
    }
  }

  &.button-type--pending, &.button-type--pending-approval {
    --currentColor: #F1A827;

    border: 1px solid #F1A827;
    background-color: #da7f29;
    color: #fff;

    &:hover {
      background-color: #D39F32;
      border: 1px solid #D39F32;
    }
  }

  &.button-type--uploaded {
    --currentColor: #4241DD;
    
    border: 1px solid #4241DD;
    background-color: #9c54d1;
    color: #fff;

    &:hover {
      background-color: #8c32cf;
      border: 1px solid #8c32cf;
    }
  }

  &.button-type--draft {
    --currentColor: #060547;

    border: 1px solid #060547;
    background-color: #513F79;
    color: #fff;

    &:hover {
      background-color: #352559;
      border: 1px solid #352559;
    }
  }

  &.button-type--pending-changes {
    --currentColor: #14B0A7;

    border: 1px solid #14B0A7;
    background-color: #14B0A7;
    color: #fff;

    &:hover {
      background-color: #0C8780;
      border: 1px solid #0C8780;
    }
  }

  &.button-type--archived {
    --currentColor: #555555;

    border: 1px solid #555555;
    background-color: #555555;
    color: #fff;

    &:hover {
      background-color: #272626;
      border: 1px solid #272626;
    }
  }

  &.button-size--medium {
    padding: 5px 15px;
  }

  &.button-size--large {
    padding: 12px 20px;
  }

  &.button-size--xl {
    padding: 15px 20px;
  }
  
  &.text-size--small {
    font-weight: 400;
    font-size: 1em;
  }
  
  &.text-size--large {
    font-weight: 400;
    font-size: 1em;
  }
  
  &.text-color--grey {
    color: var(--primary-btn-bg-grey);
  }

  &.text-color--default {
    color: #333;
  }
  
  &.Mui-disabled {
    background: var(--primary-btn-bg-disabled);
    color: #fff;
    border: 1px solid var(--primary-btn-bg-disabled);
  }
  
  &.btn-bg--red {
    background:  var(--primary-btn-bg-apricot);
    color: #fff;
    border: 1px solid  var(--primary-btn-bg-apricot);

    &:hover {
      border-color: var(--primary-btn-bg-orange);
      background: var(--primary-btn-bg-orange);
      color: #fff;
    }
  }
  
  &.disabled-point-events {
    cursor: initial;
    pointer-events: none;
  }
`;

export default StyledButton;
