/**
 * Header Template
 * Usage: <Header />
 */

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

import {
  AUTH_ROOT,
  MY_PROFILE_URL,
  ALMACENA_DOMAIN,
  EXECUTE_TRADES_URL,
  ORIGIN_URL,
} from "@lib/config/config";
import { userLogout } from "@lib/services/httpClient";
import messagesService from "@lib/services/MessagesService/messages-service";
import localizationService, {
  LanguageType,
} from "@lib/services/localization-service";
import { mediaQueryLg } from "@lib/reusable-variables";
import { WindowContext } from "@context/window-resize.context";
import { UserContext } from "@context/UserContext/user.context";
import UserIcon from "@assets/icons/User";
import {
  almacenaLogoMain,
  enFlag,
  esFlag,
  frFlag,
} from "@base/icons/icons.loader";
import MenuDropdown from "@molecules/MenuDropdown/menu-dropdown.component";
import { ItemsType } from "@molecules/MenuDropdown/menu-dropdown.types";
import SubHeader from "@templates/SubHeader/sub-header.component";

import HamburgerMenu from "./HamburgerMenu/hamburger-menu.component";
import styles from "./header.module.scss";
import SearchInput from "@organisms/SearchInput/search-input.component";
import HeaderSearch from "./HeaderSearch/header-search.component";
import SearchIcon from "@assets/icons/Search";
import { IconButton } from "@mui/material";

function Header() {
  const { t } = useTranslation();
  const [cookies, setCookies] = useCookies();
  const { clientWidth } = useContext(WindowContext);
  const userData = localStorage.getItem("user");
  const { user } = useContext(UserContext);

  const [toggleMobileSearch, setToggleMobileSearch] = useState(false);
  const logout = useCallback(() => {
    userLogout();
  }, []);

  const userInfo = useMemo(() => {
    return user !== null ? user : userData !== null ? JSON.parse(userData) : {};
  }, [user, userData]);

  const userMessage = useMemo(() => {
    return `Hello, ${userInfo?.username}!`;
  }, [userInfo]);

  const languageMenuItems: ItemsType[] = useMemo(() => {
    return [
      { key: "en", imageClassName: "flag-icon", src: enFlag },
      { key: "fr", imageClassName: "flag-icon", src: frFlag },
      { key: "es", imageClassName: "flag-icon", src: esFlag },
    ];
  }, []);

  const userMenuItems = useMemo(() => {
    return [
      {
        title: "My Profile",
        action: () => (window.location.href = MY_PROFILE_URL || ""),
      },
      {
        title: "Sign out",
        action: () => logout(),
      },
    ];
  }, [logout]);

  // On Init
  // useEffect(() => {
  //   getUser();
  // }, []);

  // const getUser = () => {
  //   messagesService.getMessages({}).then(({ data }) => {
  //     setUser(data.user);
  //     setCookies("user", data.user.name, { maxAge: 3600 });
  //   });
  // };

  const [selectedFlag, setSelectedFlag] = useState(
    languageMenuItems.find(
      (item) => item.key === localStorage.getItem("i18nextLng")
    ) || languageMenuItems[0]
  );

  const changeLanguage = (language: LanguageType) => {
    localizationService.changeLanguage(language);
    setSelectedFlag(
      languageMenuItems.find((item) => item.key === language) ||
        languageMenuItems[0]
    );
  };

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <section className={styles.menuWrapper}>
          {clientWidth < mediaQueryLg && (
            <HamburgerMenu
              logout={logout}
              userMessage={userMessage}
              user={userInfo}
            />
          )}
          <a className={styles.logoWrapper} href={ALMACENA_DOMAIN}>
            <img className={styles.logo} src={almacenaLogoMain} alt='logo' />
          </a>
          {clientWidth >= mediaQueryLg && userInfo?.isPendingUser === false && (
            <ul className={styles.navigationList}>
              <li>
                <a href={ORIGIN_URL}>{t("Almacena Origin Data")}</a>
              </li>
              <li>
                <a href={EXECUTE_TRADES_URL}>{t("Trade Dashboard")}</a>
              </li>
              <li className={styles.active}>
                <Link to='/'>{t("Inventory Dashboard")}</Link>
              </li>
              <li>
                <a href={`${ALMACENA_DOMAIN}/book-platform-demo/`}>
                  {t("Book Platform Demo")}
                </a>
              </li>
            </ul>
          )}
        </section>

        <section className={styles.userContainer}>
          {clientWidth >= mediaQueryLg ? (
            <HeaderSearch />
          ) : (
            <IconButton
              onClick={(event) => setToggleMobileSearch((current) => !current)}>
              <SearchIcon />
            </IconButton>
          )}

          {clientWidth >= mediaQueryLg && (
            <div
              className={styles.signInContainer}
              // onClick={() => userName ? logout() : window.location.replace(AUTH_ROOT)}
            >
              <UserIcon />
              {userInfo?.name ? (
                <MenuDropdown title={userMessage} openOnHover>
                  {userMenuItems.map((item) => (
                    <div
                      className={styles.userMenuItem}
                      key={item.title}
                      onClick={() => item.action()}>
                      <p>{item.title}</p>
                    </div>
                  ))}
                </MenuDropdown>
              ) : (
                <p onClick={() => logout()}>Sign In / Sign Up</p>
              )}
            </div>
          )}

          <div className={styles.languagePicker}>
            <MenuDropdown image={selectedFlag} openOnHover>
              {languageMenuItems.map((item: ItemsType, index: number) => {
                return (
                  <div
                    key={index}
                    className={styles.menuItem}
                    onClick={changeLanguage.bind(undefined, item.key)}>
                    {item.src && (
                      <img
                        className={styles.image}
                        src={item.src}
                        alt='image'
                      />
                    )}
                  </div>
                );
              })}
            </MenuDropdown>
          </div>
        </section>
      </div>
      {clientWidth < mediaQueryLg && toggleMobileSearch && <HeaderSearch />}
      <SubHeader />
    </header>
  );
}

export default Header;
