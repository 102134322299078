const CloseIcon = ({height, width}) => {
    return (
        <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1598_18853)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.321885 14.1276C-0.106443 14.556 -0.106377 15.2504 0.322017 15.6788C0.750412 16.1071 1.44491 16.1071 1.87323 15.6787L7.99952 9.55129L14.1263 15.6781C14.5547 16.1064 15.2492 16.1064 15.6775 15.6781C16.1059 15.2498 16.1059 14.5552 15.6775 14.1269L9.55061 7.99998L15.6771 1.87242C16.1053 1.44402 16.1053 0.749525 15.6769 0.321206C15.2485 -0.107123 14.554 -0.107058 14.1257 0.321338L7.9993 6.44878L1.87248 0.321864C1.44413 -0.106487 0.749622 -0.106487 0.321271 0.321864C-0.10709 0.750227 -0.10709 1.44472 0.321271 1.87309L6.44832 8.00009L0.321885 14.1276Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_1598_18853">
                    <rect width="16" height="16" fill="curentColor" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CloseIcon;