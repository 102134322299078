/**
 * Inventory Preview Page
 * @link: /inventory-management/{id}
 */

import React, { useCallback, useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";

import printIcon from "@assets/icons/print.svg";
import handleErrorsHelper from "@lib/helpers/HandleApiErrors/handle-api-errors.helper";
import { User } from "@lib/types/user.type";
import {
  statusApproved,
  statusRejected,
  statusSoldOut,
  statusArchived,
  statusPending,
  statusPendingApproval
} from "@base/globalVariables/global.variables";
import Button from "@atoms/Button/button.component";
import DynamicGrid from "@atoms/Grid/DynamicGrid/dynamic-grid.component";
import InfoBox from "@molecules/Info/InfoBox/info-box.component";
import getNameInitials from "@lib/helpers/NameInitials/get-name-initials.helper";
import inventoryService from "@lib/services/inventory-service";
import HeaderButton from "@molecules/HeaderWithBtn/header-with-btn.component";
import { AlertTypes } from "@molecules/Alert/alert.types";
import Alert from "@molecules/Alert/alert.component";
import RichTextSubmit from "@organisms/RichText/RichTextSubmit/rich-text-submit.component";
import MessagesList from "@organisms/Messages/MessagesList/messages-list.component";
import RequestDocumentsAdmin from "@organisms/RequestDocuments/RequestDocumentAdmin/request-documents-admin.component";
import RequestDocumentsUser from "@organisms/RequestDocuments/RequestDocumentUser/request-documents-user.component";
import PopupUpdateInventory from "@organisms/PopupUpdateInventory/popup-update-inventory.component";
import LoaderPage from "@templates/PageLoader/page-loader.template";
import ConfirmationPopup from "@molecules/ConfirmationPopup/confirmation-popup.component";

import {
  InfoBlock,
  PreviewDocuments,
  ShortInfo,
} from "./inventory-management.type";
import styles from "./inventory-management.module.scss";
import ActionsList from "@molecules/ActionsList/actions-list.component";
import { MarkAsSoldOutDialog } from "@molecules/Dialogs/MarkAsSoldOutDialog/mark-as-sold-out.component";

const InventoryManagement = () => {
  const { id: previewId } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);

  const [previewInformation, setPreviewInformation] = useState<
    Array<InfoBlock>
  >([]);
  const [documents, setDocuments] = useState<PreviewDocuments>(
    {} as PreviewDocuments
  );
  const [inventoryTitle, setInventoryTitle] = useState(""); // page title
  const [loader, setLoader] = useState(true);
  const [alert, setAlert] = useState<AlertTypes>({} as AlertTypes);
  const [error, setError] = useState<any>();
  const [user, setUser] = useState<User>({} as User);
  const [inventoryPageStatus, setInventoryPageStatus] = useState();
  const [inventoryOwner, setInventoryOwner] = useState(null);
  const [textAreaRef, setTextAreaRef] = useState(0);
  const [disabledSubmitButtons, setDisableSubmitButtons] = useState(false);
  const [documentSubmit, setDocumentSubmit] = useState(false);
  const [handleSendButton, setHandleSendButton] = useState(false);
  const [soldoutDialog, setSoldOutDialog] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);

  // Avoid code repeating
  const managementRoles = () => {
    return user?.isAdmin || user?.isManager;
  };

  /**
   * Check if the page status matches the provided status
   * @param {string} desiredStatus - The desired status to check against
   * @returns {boolean} - True if the page status matches the desired status, otherwise false
   */
  const isPageStatus = (desiredStatus: string) => {
    return inventoryPageStatus === desiredStatus;
  };

  const isApproved = () => isPageStatus(statusApproved);
  const isDeclined = () => isPageStatus(statusRejected);
  const isPending = () => isPageStatus(statusPending) || isPageStatus(statusPendingApproval);
  const isSoldOut = () => isPageStatus(statusSoldOut);
  const isArchived = () => isPageStatus(statusArchived);

  const actionMenu = {
    edit: {
      link: `/inventory-edit/${previewId}`,
      name: "Edit",
      icon: <EditIcon />,
    },
    archive: {
      onClick: () => { setUpdatePopup(true) },
      link: "",
      name: "Archive",
      icon: <ArchiveIcon sx={{ fill: "#F53C1E" }} />,
    },
  };

  const updateType = {
    approve: `Approve`,
    reject: `Decline`,
    update: `Update`,
  };

  //On Init
  useEffect(() => {
    getPreview();
  }, [previewId]);

  const getPreview = useCallback(() => {
    if (previewId) {
      inventoryService
        .getInventoryPreview(previewId)
        .then(({ data }) => {
          setUser(data.user); // User
          setPreviewInformation(data.single_model[0].inventory_preview); // Preview Section
          setDocuments(data.single_model[1]); // Requested Document Section
          getNameAndProducer(data.single_model[0].inventory_preview); // Get the Title name of the page.
          setInventoryPageStatus(data.single_model[3].inventory_info.status); // the status of the Inventory Page: approved | rejected | Sold Out
          setInventoryOwner(data.single_model[3].inventory_info?.isOwner);
        })
        .catch((e) => {
          handleErrorsHelper(e.response.data, setError, setAlert);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [previewId]);

  const handleInventoryArchive = () => {
    inventoryService
      .archiveInventory(previewId!)
      .then(({ data }) => {
        navigate("/")
      });
  };

  // Get the Title name of the page.
  // The name and producerId are inside Inventory Information
  const getNameAndProducer = (fields: Array<InfoBlock>) => {
    let name: string | number = "";
    let producer: string | number = "";

    fields.forEach((section: InfoBlock) => {
      if (section.title === "Inventory Information") {
        section.fields.forEach((field: ShortInfo) => {
          if (field.key === String("name")) {
            name = field.value ? field.value : "";
          } else if (field.key === String("producerId")) {
            producer = field.value ? field.value : "";
          }
        });
      }
    });
    setInventoryTitle(name);
  };

  const rejectInventory = (lotId: number, requestedReason: string) => {
    inventoryService
      .updateInventoryStatus(lotId, requestedReason, "declined")
      .then(() => {
        navigate(0);
      });
  };

  const handleStatusButtons = (buttonType: "approved" | "change" | "soldOut") => {
    setDisableSubmitButtons(true);
    inventoryService
      .updateInventoryStatus(Number(previewId), "", buttonType)
      .then(() => navigate(0))
      .finally(() => setDisableSubmitButtons(false));
  };

  // print the given component
  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const submitDocuments = () => {
    setDocumentSubmit(true);
  };

  useEffect(() => {
    if (location.state && localStorage.getItem('inventoryArchiveConfirm')) {
      setUpdatePopup(true);

      localStorage.removeItem('inventoryArchiveConfirm');
    }
  }, [location.state, updatePopup, setUpdatePopup]);

  return (
    <LoaderPage isLoading={loader}>
      <MarkAsSoldOutDialog
        inventoryName={inventoryTitle}
        opened={soldoutDialog}
        onClose={setSoldOutDialog}
        onSubmit={() => {
          setSoldOutDialog(false);
          handleStatusButtons("soldOut");
        }}
      />

      <ConfirmationPopup
        agreeText={t('Archive')}
        isOpen={updatePopup}
        title={`${t("You are about to archive")} ${inventoryTitle}`}
        setIsOpen={setUpdatePopup}
        cancellationHandler={() => { navigate('/') }}
        agreementHandler={() => { handleInventoryArchive() }}
        disableConfirmButton={false}>
        {t('Once archived, this inventory will be removed from your Inventory Dashboard')}
      </ConfirmationPopup>

      <div className={styles.userPreviewPage}>
        <Alert alert={alert} />
        <div ref={printRef} className={styles.inventoryWrapper}>
          <div className={styles.headerContainer}>
            <div className={styles.headerWrap}>
              <HeaderButton
                onClick={() => print()}
                image={printIcon}
                imageTitle={"Print"}
                status={inventoryPageStatus}>
                {inventoryTitle}
              </HeaderButton>
            </div>
          </div>

          <div className={styles.infoContainer}>
            {previewInformation.map((category: InfoBlock, index) => (
              <InfoBox
                title={category.title}
                fields={category.fields}
                key={category.title}
                actions={
                  // Show actions (Edit, Archive) only on the first info box
                  index === 0 && !isArchived() ? (
                    <div className={styles.actionMenu}>
                      <ActionsList
                        actions={actionMenu}
                        orientation={"vertical"}
                      />
                    </div>
                  ) : null
                }
              />
            ))}
          </div>
        </div>

        <div className={styles.inventoryActionable}>
          <div className={styles.inventoryActionableSection}>
            <div className={styles.inventoryActionableButtons}>
              {managementRoles() && (
                isPending() && (
                  <div className={styles.inventoryActionableButtons}>
                    <PopupUpdateInventory
                      previewInformation={previewInformation}
                      lotId={Number(previewId)}
                      updateType={updateType.reject}
                      updateHandler={rejectInventory}
                    />

                    <PopupUpdateInventory
                      previewInformation={previewInformation}
                      lotId={Number(previewId)}
                      updateType={updateType.approve}
                      updateHandler={() => handleStatusButtons("approved")}
                    />
                  </div>
                )
              )}
              
              {isApproved() && (
                <Button
                  type={"fill"}
                  size={"small"}
                  btnBg={'red'}
                  onClick={() => setSoldOutDialog(true)}
                  disabled={disabledSubmitButtons}
                >
                  {t("MarkAsSoldOut")}
                </Button> 
              )}
              
              {managementRoles() && (
                !isPending() && (
                  <Button
                    type={"fill"}
                    size={"small"}
                    onClick={() => handleStatusButtons("change")}
                    disabled={false}
                  >
                    {t("Change")}
                  </Button>
                )
              )}
            </div>
          </div>
          <div className={styles.inventoryComments}>
            <div className={`${styles.requestInformation} ${isArchived() ? styles.isArchived : ''}`}>
              {managementRoles() ? (
                <DynamicGrid columns={12}>
                  <RequestDocumentsAdmin
                    previewInformation={previewInformation}
                    inventoryId={previewId}
                    requestDocuments={documents}
                    inventoryStatus={inventoryPageStatus}
                    handleSubmit={setTextAreaRef}
                    counter={textAreaRef}
                    isMessageSendable={documentSubmit}
                    setIsMessageSendable={setDocumentSubmit}
                    isSendButtonEnabled={setHandleSendButton}
                    disabled={isArchived()}
                  />
                </DynamicGrid>
              ) : (
                <DynamicGrid columns={12}>
                  <RequestDocumentsUser
                    allowedFiles={documents.allowedFileFormats}
                    maxSize={documents.maxSize}
                    requestDocuments={documents.documents}
                    lot={inventoryTitle}
                  />
                </DynamicGrid>
              )}

              <RichTextSubmit
                handleSubmit={setTextAreaRef}
                counter={textAreaRef}
                isMessageSendable={documentSubmit}
                setIsMessageSendable={setDocumentSubmit}
                isSendButtonEnabled={setHandleSendButton}
              />

              <div className={styles.inventorySubmitMessage}>
                <Button
                  type={"fill"}
                  size={"large"}
                  onClick={submitDocuments}
                  disabled={handleSendButton || isArchived()}>
                  {t("Send Message")}
                </Button>
              </div>
            </div>
            

            <MessagesList
              type={"conversation"}
              refresh={textAreaRef}
              inventoryId={previewId}
            />
          </div>
        </div>
      </div>
    </LoaderPage>
  );
};

export default InventoryManagement;
