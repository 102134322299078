/**
 * Dialog Component
 * @props open : state of the dialog modal
 * @props title : the title of the dialog
 * @props actions : Buttons that should be pass as a JSX.Element,
 * @props content : Content of dialog modal that should be pass as a JSX.Element,
 * @props closeDialog : action that handle dialog closing,
 * Usage:
 * <DialogComponent
 *   open={true},
 *   actions={<Button type={"empty"} onClick={() => console.log("Create Account")}>
 *              Create Account
 *            </Button>},
 *   title={"Sign-in to Your Account"},
 *   content={<div style={{ fontSize: "1.5rem", textAlign: "center" }}>
 *            This page is only available to registered uses
 *           </div>},
 *   closeDialog={() => closeDialog()}
 * />
 */

import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./dialog.module.scss";

export interface DialogComponentProps extends DialogProps {
  open: boolean;
  title?: string;
  content?: JSX.Element | JSX.Element[] | any;
  actions?: JSX.Element | JSX.Element[];
  closeDialog: (param: any) => void;
}
export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...rest } = props;

  return (
    <DialogTitle
      sx={{
        marginBlock: 1,
        fontSize: "1.15em",
        display: "flex",
        alignSelf: "center",
        fontWeight: "700",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
      {...rest}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          sx={{ alignSelf: "start", marginTop: -2, marginRight: -2 }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const DialogComponent = ({
  open,
  actions,
  title,
  content,
  closeDialog,
  ...rest
}: DialogComponentProps) => {
  const handleClose = () => {
    closeDialog(false);
  };

  return (
    <Dialog
      {...rest}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth='xs'>
      <BootstrapDialogTitle onClose={handleClose}>{title}</BootstrapDialogTitle>
      <div className={styles.contentWrap}>{content}</div>
      {!!actions && <div className={styles.actionsWrap}>{actions}</div>}
    </Dialog>
  );
};

export default DialogComponent;
