import { Tooltip, Typography, Zoom } from "@mui/material";

import {TooltipComponentProps} from "./tooltip.types";

const TooltipComponent = ({children, tooltipTitle, disable = false}: TooltipComponentProps) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      enterTouchDelay={0}
      placement="top-start"
      arrow
      title={
        !disable && tooltipTitle ?
          <Typography style={{ fontSize: 16 }}>
            {tooltipTitle}
          </Typography>
          : ""
      }
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: '5px',
            maxWidth: '250px',
            color: '#fff',
            padding: '6px 10px',
            filter: 'drop-shadow(0px 8px 16px rgba(191, 191, 191, 0.25))',
          },
        },
      }}
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export default TooltipComponent;
